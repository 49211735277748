import {
  LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_LOADING,
  SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_LOADING, SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_SUCCESS, SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_ERROR,
  LOGGED_USER_DETAILS_LOADING, LOGGED_USER_DETAILS_SUCCESS, LOGGED_USER_DETAILS_ERROR,
  LOGGED_USER_PAYMENT_DEYAILS_LOADING, LOGGED_USER_PAYMENT_DEYAILS_SUCCESS, LOGGED_USER_PAYMENT_DEYAILS_ERROR,
  MEMBER_ACCESS_LEVEL_LOADING, MEMBER_ACCESS_LEVEL_SUCCESS, MEMBER_ACCESS_LEVEL_ERROR, GET_RECOMMENDED_CHAPTERS_LOADING, GET_RECOMMENDED_CHAPTERS_SUCCESS, GET_RECOMMENDED_CHAPTERS_ERROR,
  GET_FILTERED_CHAPTERS_SUCCESS,
  GET_MEMBER_ORANIZATION_PROFILE_SUCCESS,
  GET_MEMBER_ORANIZATION_PROFILE_LOADING,
  GET_MEMBER_ORANIZATION_PROFILE_ERROR,
  UPDATE_DISTANCE_LEVEL,
  GET_PAYMENT_METHOD_LOADING,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_ERROR,
  GET_ACCOUNT_SUMMARY_TRANACTIONS_LOADING,
  GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS,
  GET_ACCOUNT_SUMMARY_TRANACTIONS_ERROR,
  GET_ACCOUNT_SUMMARY_LOADING,
  GET_ACCOUNT_SUMMARY_SUCCESS,
  GET_ACCOUNT_SUMMARY_ERROR,
  GET_PAYMENT_PLAN_ADMIN_LIST_LOADING,
  GET_PAYMENT_PLAN_ADMIN_LIST_SUCCESS,
  GET_PAYMENT_PLAN_ADMIN_LIST_ERROR,
  GET_PAYMENT_PLAN_DETAIL_LOADING,
  GET_PAYMENT_PLAN_DETAIL_SUCCESS,
  GET_PAYMENT_PLAN_DETAIL_ERROR,
  GET_ADMIN_DASHBOARD_LOADING,
  GET_ADMIN_DASHBOARD_SUCCESS,
  GET_ADMIN_DASHBOARD_ERROR,
  GET_PRODUCTS_BY_ORGANIZATION_LOADING,
  GET_PRODUCTS_BY_ORGANIZATION_SUCCESS,
  GET_PRODUCTS_BY_ORGANIZATION_ERROR,
  GET_PRODUCT_LOADING,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  GET_POSSIBLE_PRODUCT_GROUP_LOADING,
  GET_POSSIBLE_PRODUCT_GROUP_SUCCESS,
  GET_POSSIBLE_PRODUCT_GROUP_ERROR,
  GET_DOCUMENT_DETAIL_LOADING,
  GET_DOCUMENT_DETAIL_SUCCESS,
  GET_DOCUMENT_DETAIL_ERROR,
  GET_DUES_SUMMARY_LOADING,
  GET_DUES_SUMMARY_SUCCESS,
  GET_DUES_SUMMARY_ERROR,
  GET_MEMBERS_LIST_LOADING,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_ERROR,
  GET_CANDIDATES_LIST_SUCCESS,
  GET_CANDIDATES_LIST_ERROR,
  GET_CANDIDATES_LIST_LOADING,
  GET_LEADERSHIP_ORGANIZATIONS_LIST_SUCCESS,
  GET_LEADERSHIP_ORGANIZATIONS_LIST_LOADING,
  GET_LEADERSHIP_ORGANIZATIONS_LIST_ERROR,
  GET_MEMBER_FILTER_OPTIONS_LOADING,
  GET_MEMBER_FILTER_OPTIONS_SUCCESS,
  GET_MEMBER_FILTER_OPTIONS_ERROR,
  GET_CANDIDATE_FILTER_OPTIONS_LOADING,
  GET_CANDIDATE_FILTER_OPTIONS_SUCCESS,
  GET_CANDIDATE_FILTER_OPTIONS_ERROR,
  GET_MEMBER_PROFILE_DETAILS_LOADING,
  GET_MEMBER_PROFILE_DETAILS_SUCCESS,
  GET_MEMBER_PROFILE_DETAILS_ERROR,
  GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  GET_CANDIDATE_PROFILE_DETAILS_ERROR,
  GET_CANDIDATE_PROFILE_DETAILS_LOADING,
  GET_MEMBER_CALENDER_EVENT_LOADING,
  GET_MEMBER_CALENDER_EVENT_SUCCESS,
  GET_MEMBER_CALENDER_EVENT_ERROR,
  GET_PURCHASE_ORDER_DETAILS_LOADING,
  GET_PURCHASE_ORDER_DETAILS_SUCCESS,
  GET_PURCHASE_ORDER_DETAILS_ERROR,
  GET_RPT_PAYMENT_DETAILS_LOADING,
  GET_RPT_PAYMENT_DETAILS_SUCCESS,
  GET_RPT_PAYMENT_DETAILS_ERROR,
  GET_AUTO_DEBIT_LOGS_LOADING,
  GET_AUTO_DEBIT_LOGS_SUCCESS,
  GET_AUTO_DEBIT_LOGS_ERROR,
  GET_UPCOMING_AUTO_DEBITS_LOADING,
  GET_UPCOMING_AUTO_DEBITS_SUCCESS,
  GET_UPCOMING_AUTO_DEBITS_ERROR,
  GET_ENTITY_EXAM_TRANSACTIONS_LOADING,
  GET_ENTITY_EXAM_TRANSACTIONS_SUCCESS,
  GET_ENTITY_EXAM_TRANSACTIONS_ERROR,
  GET_XREF_TYPES_LOADING,
  GET_XREF_TYPES_SUCCESS,
  GET_XREF_TYPES_ERROR,
  GET_COURSES_BY_CURRENT_SEASON_LOADING,
  GET_COURSES_BY_CURRENT_SEASON_SUCCESS,
  GET_COURSES_BY_CURRENT_SEASON_ERROR,
  GET_OFFICER_REPORT_STATUS_LOADING,
  GET_OFFICER_REPORT_STATUS_SUCCESS,
  GET_OFFICER_REPORT_STATUS_ERROR,
  GET_EVENT_FILTER_OPTIONS_LOADING,
  GET_EVENT_FILTER_OPTIONS_SUCCESS,
  GET_EVENT_FILTER_OPTIONS_ERROR,

  GET_OFFICER_REPORT_FILTER_OPTIONS_LOADING,
  GET_OFFICER_REPORT_FILTER_OPTIONS_SUCCESS,
  GET_OFFICER_REPORT_FILTER_OPTIONS_ERROR,

  GET_SCHOOLS_LIST_SUCCESS,
  GET_SCHOOLS_LIST_ERROR,
  GET_SCHOOLS_LIST_LOADING,
  GET_SCHOOL_PROFILE_DETAILS_LOADING,
  GET_SCHOOL_PROFILE_DETAILS_SUCCESS,
  GET_SCHOOL_PROFILE_DETAILS_ERROR,
  GET_COLLEGE_OFFICIAL_DETAILS_LOADING,
  GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS,
  GET_COLLEGE_OFFICIAL_DETAILS_ERROR,
  GET_COLLEGEOFFICIAL_LIST_LOADING,
  GET_COLLEGEOFFICIAL_LIST_SUCCESS,
  GET_COLLEGEOFFICIAL_LIST_ERROR,
  GET_FINANCIAL_STATUS_LOADING,
  GET_FINANCIAL_STATUS_SUCCESS,
  GET_FINANCIAL_STATUS_ERROR,
  GET_SETTING_CONFIGS_LOADING,
  GET_SETTING_CONFIGS_SUCCESS,
  GET_SETTING_CONFIGS_ERROR,
  GET_MEMBER_TIMELINE_LOADING,
  GET_MEMBER_TIMELINE_SUCCESS,
  GET_MEMBER_TIMELINE_ERROR,
  GET_POSITON_TYPES_LOADING,
  GET_POSITON_TYPES_SUCCESS,
  GET_POSITON_TYPES_ERROR,
  GET_POSITON_DETAILS_LOADING,
  GET_POSITON_DETAILS_SUCCESS,
  GET_POSITON_DETAILS_ERROR,
  GET_POSITON_FILTER_LOADING,
  GET_POSITON_FILTER_SUCCESS,
  GET_POSITON_FILTER_ERROR,
  LOGGED_USER_DETAILS_NAVBAR_SUCCESS,
  LOGGED_USER_DETAILS_NAVBAR_ERROR,
  SCHOOL_LOGGED_USER_DETAILS_SUCCESS,
  GET_EVENT_LIST_LOADING,
  GET_EVENT_LIST_SUCCESS,
  GET_EVENT_LIST_ERROR,
  GET_EVENT_DETAILS_LOADING,
  GET_EVENT_DETAILS_SUCCESS,
  GET_EVENT_DETAILS_ERROR,
  GET_TICKET_LIST_LOADING,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST_ERROR,
  GET_TICKET_DETAILS_ERROR,
  GET_TICKET_DETAILS_SUCCESS,
  GET_TICKET_DETAILS_LOADING,
  GET_TICKET_FILTER_SUCCESS,
  GET_TICKET_FILTER_ERROR,
  GET_TICKET_FILTER_LOADING,
  GET_FEE_DETERMINE_LOADING,
  GET_FEE_DETERMINE_SUCCESS,
  GET_FEE_DETERMINE_ERROR,
  GET_CALENDER_EVENT_DETAILS_SUCCESS,
  GET_CALENDER_EVENT_DETAILS_ERROR,
  GET_CALENDER_EVENT_DETAILS_LOADING,
  GET_EVENT_CHECKOUT_DETAILS_LOADING,
  GET_EVENT_CHECKOUT_DETAILS_SUCCESS,
  GET_EVENT_CHECKOUT_DETAILS_ERROR,
  GET_DOCUMENT_LIST_LOADING,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_ERROR,
  GET_DOCUMENT_FILTER_OPTIONS_SUCCESS,
  GET_DOCUMENT_FILTER_OPTIONS_LOADING,
  GET_DOCUMENT_FILTER_OPTIONS_ERROR,
  GET_DOCUMENT_FORMS_LOADING,
  GET_DOCUMENT_FORMS_ERROR,
  GET_DOCUMENT_FORMS_SUCCESS,
  GET_APPROVAL_STEPS_LOADING,
  GET_APPROVAL_STEPS_SUCCESS,
  GET_APPROVAL_STEPS_ERROR,
  GET_INTAKE_SEASON_STEPS_LOADING,
  GET_INTAKE_SEASON_STEPS_SUCCESS,
  GET_INTAKE_SEASON_STEPS_ERROR,
  GET_SIGNER_POSITIONS_LOADING,
  GET_SIGNER_POSITIONS_SUCCESS,
  GET_SIGNER_POSITIONS_ERROR,
  GET_APPROVAL_STEP_BY_ID_LOADING,
  GET_APPROVAL_STEP_BY_ID_SUCCESS,
  GET_APPROVAL_STEP_BY_ID_ERROR,
  GET_DOCUMENT_FORM_BY_ID_LOADING,
  GET_DOCUMENT_FORM_BY_ID_SUCCESS,
  GET_DOCUMENT_FORM_BY_ID_ERROR,
  GET_DOCUMENT_FORM_TYPES_LOADING,
  GET_DOCUMENT_FORM_TYPES_ERROR,
  GET_DOCUMENT_FORM_TYPES_SUCCESS,
  GET_DIVISIONS_LOADING,
  GET_DIVISIONS_SUCCESS,
  GET_DIVISIONS_ERROR,
  GET_APPROVAL_STEP_STATUS_LOADING,
  GET_APPROVAL_STEP_STATUS_SUCCESS,
  GET_APPROVAL_STEP_STATUS_ERROR,
  GET_DOCUMENT_FORM_FILTERS_LOADING,
  GET_DOCUMENT_FORM_FILTERS_SUCCESS,
  GET_DOCUMENT_FORM_FILTERS_ERROR,
  GET_SIGNER_POSITION_TYPE_LOADING,
  GET_SIGNER_POSITION_TYPE_SUCCESS,
  GET_SIGNER_POSITION_TYPE_ERROR,
  GET_POSITONS_SUCCESS,
  GET_POSITONS_ERROR,
  GET_POSITONS_LOADING,
  GET_ENTITY_SELECTION_SUCCESS,
  GET_ENTITY_SELECTION_ERROR,
  GET_ENTITY_SELECTION_LOADING,
  SET_OFFICER_REPORT_STEP,
  GET_SIGNQUEUE_LIST_LOADING,
  GET_SIGNQUEUE_LIST_SUCCESS,
  GET_SIGNQUEUE_LIST_ERROR,
  GET_PLANNING_DOCUMENT_EVENT_LOADING,
  GET_PLANNING_DOCUMENT_EVENT_SUCCESS,
  GET_PLANNING_DOCUMENT_EVENT_ERROR,
  GET_PLANNING_DOCUMENT_DETAILS_SUCCESS,
  GET_PLANNING_DOCUMENT_DETAILS_ERROR,
  GET_PLANNING_DOCUMENT_DETAILS_LOADING,
  GET_PLANNING_DOCUMENT_FILTERS_LOADING,
  GET_PLANNING_DOCUMENT_FILTERS_SUCCESS,
  GET_PLANNING_DOCUMENT_FILTERS_ERROR,
  GET_SCHOOLS_LIST_BY_ORGID_SUCCESS,
  GET_SCHOOLS_LIST_BY_ORGID_ERROR,
  GET_SCHOOLS_LIST_BY_ORGID_LOADING,
  GET_EXAM_TRANSACTION_RESULT_LOADING,
  GET_EXAM_TRANSACTION_RESULT_SUCCESS,
  GET_EXAM_TRANSACTION_RESULT_ERROR,
  GET_EXAM_SECTIONS_LOADING,
  GET_EXAM_SECTIONS_SUCCESS,
  GET_EXAM_SECTIONS_ERROR,
  GET_ORGANIZATION_DETAILS_FOR_PROSPECT_LOADING,
  GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS,
  GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR,
  GET_PLANNING_DOCUMENT_EVENT_CHECKIN_LOADING,
  GET_PLANNING_DOCUMENT_EVENT_CHECKIN_SUCCESS,
  GET_PLANNING_DOCUMENT_EVENT_CHECKIN_ERROR,
  GET_PROSPECT_APPLICATION_STATUS_LOADING,
  GET_PROSPECT_APPLICATION_STATUS_SUCCESS,
  GET_PROSPECT_APPLICATION_STATUS_ERROR,
  GET_PROSPECT_DETAILS_LOADING,
  GET_PROSPECT_DETAILS_SUCCESS,
  GET_PROSPECT_DETAILS_ERROR,
  GET_DOCUMENT_PREQUISITES_LOADING,
  GET_DOCUMENT_PREQUISITES_SUCCESS,
  GET_DOCUMENT_PREQUISITES_ERROR,
  GET_MEMBERSHIP_APPLICATION_SETTING_LOADING,
  GET_MEMBERSHIP_APPLICATION_SETTING_SUCCESS,
  GET_MEMBERSHIP_APPLICATION_SETTING_ERROR,
  GET_QUESTIONS_BY_SECTION_LOADING,
  GET_QUESTIONS_BY_SECTION_SUCCESS,
  GET_QUESTIONS_BY_SECTION_ERROR,
  GET_APPLICATION_REQUIRED_DOCUMENT_LOADING,
  GET_APPLICATION_REQUIRED_DOCUMENT_SUCCESS,
  GET_REFERENCE_LETTER_LIST_LOADING,
  GET_REFERENCE_LETTER_LIST_SUCCESS,
  GET_REFERENCE_LETTER_LIST_ERROR,
  GET_LATEST_APPROVED_OFFICER_REPORT_LOADING,
  GET_LATEST_APPROVED_OFFICER_REPORT_SUCCESS,
  GET_LATEST_APPROVED_OFFICER_REPORT_ERROR,
  GET_PLANNING_DOCUMENT_EVENTS_SUCCESS,
  GET_PLANNING_DOCUMENT_EVENTS_ERROR,
  GET_PLANNING_DOCUMENT_EVENTS_LOADING,
  GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_LOADING,
  GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_SUCCESS,
  GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_ERROR,
  GET_REGIONS_LOADING,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
  GET_MASTERINTAKE_DETAILS_LOADING,
  GET_MASTERINTAKE_DETAILS_SUCCESS,
  GET_MASTERINTAKE_DETAILS_ERROR,
  GET_OFFICER_REPORT_DETAILS_LOADING,
  GET_OFFICER_REPORT_DETAILS_SUCCESS,
  GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_SUCCESS,
  GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_ERROR,
  GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_LOADING,
  GET_INVITATION_REQUESTS_LOADING,
  GET_INVITATION_REQUESTS_SUCCESS,
  GET_INVITATION_REQUESTS_ERROR,
  GET_PURCHASE_ORDER_LIST_LOADING,
  GET_PURCHASE_ORDER_LIST_SUCCESS,
  GET_PURCHASE_ORDER_LIST_ERROR,
  GET_INTAKE_SEASON_LOADING,
  GET_INTAKE_SEASON_ERROR,
  GET_INTAKE_SEASON_SUCCESS,
  GET_COURSES_LOADING,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_EXAMS_BY_COURSE_LOADING,
  GET_EXAMS_BY_COURSE_SUCCESS,
  GET_EXAMS_BY_COURSE_ERROR,
  GET_CHAPTER_ACTIVITY_LOADING,
  GET_CHAPTER_ACTIVITY_SUCCESS,
  GET_CHAPTER_ACTIVITY_ERROR,
  GET_HARD_LIST_LOADING,
  GET_HARD_LIST_ERROR,
  GET_HARD_LIST_SUCCESS,
  GET_MEMBER_MANAGED_ENTITIES_LOADING,
  GET_MEMBER_MANAGED_ENTITIES_SUCCESS,
  GET_MEMBER_MANAGED_ENTITIES_ERROR,
  GET_INTAKE_PAYMENT_SUMMARY_ERROR,
  GET_INTAKE_PAYMENT_SUMMARY_SUCCESS,
  GET_INTAKE_PAYMENT_SUMMARY_LOADING,
  GET_CHAPTER_DUES_SUMMARY_ERROR,
  GET_CHAPTER_DUES_SUMMARY_SUCCESS,
  GET_CHAPTER_DUES_SUMMARY_LOADING,
  GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_SUCCESS,
  GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR,
  GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_LOADING,
  GET_FINANCE_PRODUCT_TRANSPORT_REPORT_LOADING,
  GET_FINANCE_PRODUCT_TRANSPORT_REPORT_SUCCESS,
  GET_FINANCE_PRODUCT_TRANSPORT_REPORT_ERROR,
  GET_FINANCE_REPORTING_FILTER_LOADING,
  GET_FINANCE_REPORTING_FILTER_SUCCESS,
  GET_FINANCE_REPORTING_FILTER_ERROR,
  UPCOMING_FISCAL_YEAR_DETAIL_ERROR,
  UPCOMING_FISCAL_YEAR_DETAIL_SUCCESS,
  UPCOMING_FISCAL_YEAR_DETAIL_LOADING,
  GET_INVITES_LOADING,
  GET_INVITES_SUCCESS,
  GET_INVITES_ERROR,
  GET_DASHBOARD_TABS_LOADING,
  GET_DASHBOARD_TABS_SUCCESS,
  GET_DASHBOARD_TABS_ERROR,
  GET_QUICK_LINKS_LOADING,
  GET_QUICK_LINKS_SUCCESS,
  GET_QUICK_LINKS_ERROR,
  GET_ROSTER_REPORT_STATUS_LOADING,
  GET_ROSTER_REPORT_STATUS_SUCCESS,
  GET_ROSTER_REPORT_STATUS_ERROR,
  GET_DOCUMENT_FORMBYENTITY_LOADING,
  GET_DOCUMENT_FORMBYENTITY_SUCCESS,
  GET_DOCUMENT_FORMBYENTITY_ERROR,
  GET_TICKET_PURCHASE_ORDER_HISTORY_SUCCESS,
  GET_TICKET_PURCHASE_ORDER_HISTORY_LOADING,
  GET_TICKET_PURCHASE_ORDER_HISTORY_ERROR,
  GET_MEMBER_PENDING_ROSTER_ENTRIES_LOADING,
  GET_MEMBER_PENDING_ROSTER_ENTRIES_SUCCESS,
  GET_MEMBER_PENDING_ROSTER_ENTRIES_ERROR,
  GET_EXPORT_CANDIDATES_LIST_LOADING,
  GET_EXPORT_CANDIDATES_LIST_SUCCESS,
  GET_EXPORT_CANDIDATES_LIST_ERROR,
  GET_EXPORT_MEMBERS_LIST_LOADING,
  GET_EXPORT_MEMBERS_LIST_SUCCESS,
  GET_EXPORT_MEMBERS_LIST_ERROR,
  GET_REQUIRED_DOCUMENT_LIST_LOADING,
  GET_REQUIRED_DOCUMENT_LIST_SUCCESS,
  GET_REQUIRED_DOCUMENT_LIST_ERROR,
} from "./ActionTypes";
import API from "./API";
import {
  GET_ACCOUNT_SUMMARY_TRANACTIONS_URL, GET_ACCOUNT_SUMMARY_URL, GET_ADMIN_DASHBOARD_URL,
  GET_COUNTRY_STATE_LIST, GET_DOCUMENT_DETAIL_URL, GET_DUES_PRODUCTSUMMARY_URL, GET_FILTERED_CHAPTERS_LIST,
  GET_LEADERSHIP_ORGANIZATIONS_LIST_URL, GET_LOGIN_API, GET_MEMBERS_LIST_URL, GET_CANDIDATE_LIST_URL, GET_MEMBER_ACCESS_LEVEL_URL,
  GET_MEMBER_FILTER_OPTIONS_URL, GET_CANDIDATE_FILTER_OPTIONS_URL, GET_MEMBER_ORGANIZATION_PROFILE_URL, GET_MEMBER_PROFILE,
  GET_MEMBER_PROFILE_DETAILS_URL, GET_MEMBER_DASBOARD_URL, GET_CANDIDATE_PROFILE_DETAILS_URL, GET_PAYMENT_METHODS_URL,
  GET_PAYMENT_PLAN_ADMIN_LIST_URL, GET_PAYMENT_PLAN_DETAIL_URL, GET_POSSIBLE_PRODUCT_GROUP_URL,
  GET_PRODUCTS_BY_ORGANIZATION_URL, GET_PRODUCT_URL, GET_PURCHASE_ORDER_DETAILS_URL, GET_RECOMMENDED_CHAPTERS_LIST,
  GET_AUTO_DEBIT_LOGS_URL, GET_RPT_PAYMENT_DETAILS_URL, MEMBER_PAYMENT_PLAN_DETAILS_URL, GET_UPCOMING_AUTO_DEBITS_URL,
  GET_ENTITY_EXAM_TRANSACTIONS_URL, GET_FILTERED_MEMBERS_LIST, GET_ADMINISTRATIVE_GROUPS_URL, ADD_MEMBER_TO_GROUP_URL,
  GET_ACCESS_LIST_URL, DELETE_MEMBER_FROM_GROUP_URL, ADD_POSITION_TO_GROUP_URL, DELETE_POSITION_FROM_GROUP_URL, GET_XREF_TYPES_URL,
  GET_COURSES_BY_CURRENT_SEASON_URL, GET_OFFICER_REPORT_STATUS_URL, GET_EVENT_FILTER_OPTIONS_URL, GET_OFFICER_REPORT_FILTER_OPTIONS_URL,
  GET_SCHOOL_LIST_URL, GET_SCHOOL_PROFILE_DETAILS_URL, GET_COLLEGE_OFFICIAL_DETAILS_URL, GET_COLLEGE_OFFICIAL_LIST_URL,
  GET_FINANCIAL_STATUS_URL, GET_SETTING_CONFIGS_URL, GET_MEMBER_TIMELINE_URL, GET_POSTIONS_URL, GET_POSTION_DETAILS_URL, GET_POSTION_FILTER_URL,
  GET_EVENT_LIST_URL, GET_EVENT_DETAILS_URL, GET_TICKET_LIST_URL, GET_TICKET_DETAILS_URL, GET_TICKET_FILTER_URL, GET_FEE_DETERMINE_URL,
  GET_CALENDER_EVENT_DETAILS_URL, GET_EVENT_CHECKOUT_DETAILS_API, GET_DOCUMENT_LIST_URL, GET_DOCUMENT_FILTER_OPTIONS_URL, GET_DOCUMENT_FORMS_URL,
  GET_APPROVAL_STEPS_URL, GET_SIGNER_POSITIONS_URL, GET_APPROVAL_STEP_BY_ID_URL, GET_DOCUMENT_FORM_TYPES_URL,
  GET_DIVISIONS_URL, GET_APPROVAL_STEP_STATUS_LIST_URL, GET_DOCUMENT_FORM_FILTER_URL, GET_SIGNER_POSITIONTYPES_URL, GET_POSTION_TYPES_URL, GET_ENTITY_SELECTIONS,
  ADD_MEMBER_TO_ROSTER_REPORT, MEMBER_FROMROSTER_REPORT_URL, GET_SIGN_QUEUE_URL, GET_PLANNING_DOCUMENT_EVENT_LIST_URL, GET_PLANNING_DOCUMENT_EVENT_DETAILS_URL,
  GET_PLANNING_DOCUMENT_EVENT_FILTERS_URL,
  GET_SCHOOL_LIST_BY_ORGID_URL,
  GET_EXAMTRANSACTIONRESULT_URL,
  GET_EXAM_SECTIONS_URL,
  GET_ORGANIZATION_DETAIL_FOR_PROSPECT_API,
  GET_PLANNING_DOCUMENT_EVENT_CHECKIN_API,
  GET_PROSPECT_APPLICATION_STATUS_URL,
  GET_PROSPECT_DETAILS_URL,
  GET_ALL_DOCUMENT_PREQUISITES_URL,
  GET_MEMBERSHIP_APPLICATION_SETTING_URL,
  GET_QUESTIONS_BY_SECTION_URL,
  GET_APPLICATION_REQUIRED_DOCUMENT_URL,
  GET_REFERENCE_LETTER_LIST_URL,
  GET_LATEST_APPROVED_OFFICER_REPORT_URL,
  GET_PLANNING_DOCUMENT_EVENTS_API,
  GET_REGIONS_LIST_URL,
  GET_CHAPTER_INTERVIEWS_URL,
  GET_MASTERINTAKE_DETAILS_API,
  GET_OFFICER_REPORT_DETAILS_URL,
  GET_COLLEGE_OFFICIAL_SCHOOL_HISTORY,
  GET_INVITATION_REQUESTS_URL,
  GET_INVITATION_REQUESTS_BY_ORGANIZATION_URL,
  GET_PURCHASE_ORDERS_URL,
  GET_ALL_INTAKE_SEASON_URL,
  GET_NATIONAL_INTAKE_SEASON_URL,
  GET_INTAKE_SEASON_STATUS_URL,
  GET_COURSES_URL,
  GET_Exams_BY_COURSE_URL,
  SET_STEP_STATUS_URL,
  CANCEL_REFUND_PAYMENT_URL,
  GET_CHAPTER_ACTIVITY_BY_CATEGORYID_URL,
  OVERRIDE_OFFICER_REPORT_STEP_URL,
  GET_HARD_LIST_URL,
  GET_MEMBER_MANAGED_ENTITIES_URL,
  DELETE_COLLEGE_OFFICIAL_ENTITY_URL,
  GET_INTAKE_PAYMENT_SUMMARY_URL,
  CHAPTER_DUES_SUMMARY_URL,
  GET_FINANCE_PRODUCT_TRANSPORT_REPORT_URL,
  GET_FINANCE_REPORTING_FILTER_URL,
  UPCOMING_FISCAL_YEAR_DETAIL_URL,
  GET_INVITES_URL,
  GET_ADMIN_DASHBOARD_TABS_URL,
  GET_ADMIN_DASHBOARD_QUICKLINKS_URL,
  GET_ROSTER_REPORT_STATUS_URL,
  GET_DOCUMENT_FORMBYENTITY_URL,
  ROSTER_REPORT_SET_STEP_STATUS_URL,
  GET_TICKET_PURCHASE_ORDER_HISTORY_URL,
  GET_MEMBER_PENDING_ROSTER_ENTRIES_URL,
  GET_REQUIRED_DOCUMENT_LIST_URL
} from "../../constants/apiPaths";
import { CustomAlertMessage } from "../../functions/CustomFunctions";
import { LOCALSTORAGE_CURRENT_ORGANIZATIONID, LOCALSTORAGE_AUTHTOKEN, LOCALSTORAGE_AUTHUSER, LOCALSTORAGE_AUTH_MEMBER_ID, PAYMENT_METHOD_CUSTOMER_TOKEN, LOGGED_USER_ACCESS_LEVEL, REGION_ID } from "../../constants/LocalStorage";
import { DASHBOARD_PATH, LAYOUT_INITIAL_PATH } from "../../constants/RoutePaths";
import { authHeader, getLoggedEntityTypeID, getLoggedSourceOwnerID, getParentOrganizationID } from "../../functions/CoreFunctions";

//Dashboard
//Get Dashboard Details
export function authLogin(formData, history, enqueueSnackbar) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING,
      payload: ""
    });
    API.post(GET_LOGIN_API, formData)
      .then(response => {
        let {
          accessToken: { token = "" },
          // email = "",
          userName = "",
          role = "",
          userId
        } = response.data;
        if (role === 'admin') {
          let loggedUserData = { userId, userName };
          localStorage.setItem(LOCALSTORAGE_AUTHTOKEN, token);
          localStorage.setItem(
            LOCALSTORAGE_AUTHUSER,
            JSON.stringify(loggedUserData)
          );
          CustomAlertMessage("Login Success", "success", enqueueSnackbar);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: loggedUserData
          });
          history.push(DASHBOARD_PATH);
        }
        else {
          CustomAlertMessage('Invalid User', "error", enqueueSnackbar);
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          CustomAlertMessage(data.errorMessage, "error", enqueueSnackbar);
          dispatch({
            type: LOGIN_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Sign Up Form Field Options
export function getSignUpStepsFormFieldOptions(enqueueSnackbar) {
  return async dispatch => {
    dispatch({
      type: SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COUNTRY_STATE_LIST,
      headers: authHeader()
    };
    API(options).then(response => {
      const { Response = {} } = response.data;
      const {
        countries = [],
        usStates = [],
        timeZones = []
      } = Response;
      dispatch({
        type: SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_SUCCESS,
        countries,
        usStates,
        timeZones
      });
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          CustomAlertMessage(data.message, 'error', enqueueSnackbar);
          dispatch({
            type: SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_ERROR,
            payload: data.message,
          });
        }
      });
  };
}

//Get Logged User Details
export function getLoggedUserDetails(MemberID) {
  return async dispatch => {
    const loggedMemberId = localStorage.getItem(LOCALSTORAGE_AUTH_MEMBER_ID);
    dispatch({
      type: LOGGED_USER_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_PROFILE,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: LOGGED_USER_DETAILS_SUCCESS,
          payload: initialResponse
        });
        if (MemberID == loggedMemberId)
          dispatch({
            type: LOGGED_USER_DETAILS_NAVBAR_SUCCESS,
            payload: initialResponse
          });
      }
      else {
        dispatch({
          type: LOGGED_USER_DETAILS_ERROR,
          payload: initialResponse.message
        });
        if (MemberID == loggedMemberId)
          dispatch({
            type: LOGGED_USER_DETAILS_NAVBAR_ERROR,
            payload: initialResponse.message
          });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: LOGGED_USER_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Logged User Payment Details
export function getLoggedPaymentDetails(MemberID, setExistingPlanDetail) {
  return async dispatch => {
    dispatch({
      type: LOGGED_USER_PAYMENT_DEYAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: MEMBER_PAYMENT_PLAN_DETAILS_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { response: initialResponse = {} } = response.data;
      const { paymentPlan = {} } = initialResponse
      if (setExistingPlanDetail && Object.keys(initialResponse).length && Object.keys(initialResponse.paymentPlan).length) {
        setExistingPlanDetail({
          paymentPlan: initialResponse.paymentPlan,
          paymentPlanItem: initialResponse.paymentPlanItem,
          paymentPlanItemID: initialResponse.paymentPlanItemID,
          paymentPlanLineItems: initialResponse.paymentPlanLineItems,
          paymentPlanTransactions: initialResponse.paymentPlanTransactions,
          // paymentPlans: initialResponse.paymentPlans,
          // termText: initialResponse.TermText,
          // total: initialResponse.Total
        })
      } else {
        setExistingPlanDetail({
          paymentPlan: {},
          paymentPlanItem: '',
          paymentPlanLineItems: [],
          paymentPlanItemID: 0,
          paymentPlanTransactions: [],
          paymentPlans: [],
          termText: '',
          total: 0
        })
      }
      dispatch({
        type: LOGGED_USER_PAYMENT_DEYAILS_SUCCESS,
        payload: initialResponse
      });
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: LOGGED_USER_PAYMENT_DEYAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Member Access Level
export function getMemberAccessLevel(MemberID, history) {
  return async dispatch => {
    dispatch({
      type: MEMBER_ACCESS_LEVEL_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_ACCESS_LEVEL_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status, AccessLevel = '', Region = {} } = response.data;
      const { groupOrganizationId = 0 } = Region;
      if (parseInt(status) === 1) {
        dispatch({
          type: MEMBER_ACCESS_LEVEL_SUCCESS,
          payload: response.data
        });
        localStorage.setItem(LOGGED_USER_ACCESS_LEVEL, AccessLevel);
        localStorage.setItem(REGION_ID, groupOrganizationId);
        if (history)
          history.push(LAYOUT_INITIAL_PATH);
      }
      else {
        dispatch({
          type: MEMBER_ACCESS_LEVEL_ERROR,
          payload: response.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: MEMBER_ACCESS_LEVEL_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Recommended Chapters
export function getRecommendedChaptersList(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_RECOMMENDED_CHAPTERS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_RECOMMENDED_CHAPTERS_LIST,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_RECOMMENDED_CHAPTERS_SUCCESS,
          payload: response.data
        });
        dispatch({
          type: UPDATE_DISTANCE_LEVEL,
          payload: paramsData.DistanceMiles
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_RECOMMENDED_CHAPTERS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Filtered Members
export function getFilteredMemberList(paramsData, callback) {
  return dispatch => {
    // dispatch({
    //   type:GET_RECOMMENDED_CHAPTERS_LOADING,
    // });
    const options = {
      method: 'GET',
      url: GET_FILTERED_MEMBERS_LIST,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) == 1) {
        if (callback)
          callback(response.data.response.data)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_RECOMMENDED_CHAPTERS_ERROR,
            payload: data.message
          });
        }
      })
      .finally(() => callback(null, false));
  };
}

//Get Filtered Chapters
export function getFilteredChaptersList(paramsData, callback) {
  return async dispatch => {
    dispatch({
      type: GET_RECOMMENDED_CHAPTERS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_FILTERED_CHAPTERS_LIST,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        if (callback)
          callback(response.data.response)
        else
          dispatch({
            type: GET_FILTERED_CHAPTERS_SUCCESS,
            payload: response.data
          });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_RECOMMENDED_CHAPTERS_ERROR,
            payload: data.message
          });
        }
      })
      .finally(() => {
        if (callback)
          callback(null, false)
      }
      );
  };
}

//Get Member Organization Profile
export function getMemberOrganizationProfile(MemberID) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_ORANIZATION_PROFILE_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_ORGANIZATION_PROFILE_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status, response: apiData } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_MEMBER_ORANIZATION_PROFILE_SUCCESS,
          payload: apiData
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_ORANIZATION_PROFILE_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Payment Methods
export function getPaymentMethods(paramsData, setActiveTab) {
  return async dispatch => {
    dispatch({
      type: GET_PAYMENT_METHOD_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PAYMENT_METHODS_URL,
      headers: authHeader(),
      params: { ...paramsData, ParentOrganizationID: getParentOrganizationID() }
    };
    API(options).then(response => {
      const { status, response: paymentMethods = {} } = response.data;
      if (parseInt(status) === 1) {
        const { customerToken = '', achPays: achPaysList = [], ccPas: ccPasList = [] } = paymentMethods;
        localStorage.setItem(PAYMENT_METHOD_CUSTOMER_TOKEN, customerToken)
        if ((achPaysList.length > 0 || ccPasList.length > 0) && setActiveTab) {
          setActiveTab(2);
        } else if (setActiveTab) {
          setActiveTab(1);

        }
        dispatch({
          type: GET_PAYMENT_METHOD_SUCCESS,
          payload: paymentMethods
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PAYMENT_METHOD_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Account Summary
export function getAccountSummary(paramsData, setActiveTab) {
  return async dispatch => {
    dispatch({
      type: GET_ACCOUNT_SUMMARY_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ACCOUNT_SUMMARY_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const {
          purchaseOrderStatus = [],
          summary: {
            presentBalance = 0,
            withdrawalBalance = 0,
            numericalWithdrawalBalance = 0,
            achPayId,
            bankAccountName,
            bankAccountNumber,
            entityName,
            lastHundredtransactions = [],
            lastHundredtransactionsByPurchaseOrders = [],
            candidatesThatNeedRefund = [],
            paymentRefundRequestTransactions = [],
            bankHasForteToken = false,
            hasPendingWithdrawal = false
          }
        } = apiResponse;
        dispatch({
          type: GET_ACCOUNT_SUMMARY_SUCCESS,
          payload: {
            purchaseOrderStatus,
            presentBalance,
            withdrawalBalance,
            numericalWithdrawalBalance,
            achPayId,
            bankAccountName,
            bankAccountNumber,
            entityName,
            candidatesThatNeedRefund,
            paymentRefundRequestTransactions,
            bankHasForteToken,
            hasPendingWithdrawal
          }
        });
        dispatch({
          type: GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS,
          payload: {
            lastHundredtransactions,
            lastHundredtransactionsByPurchaseOrders
          },
          totalCount: 100,
          appendWithPreviousList: false
        });
        if (paymentRefundRequestTransactions.length > 0 && setActiveTab) {
          setActiveTab(2);
        }
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ACCOUNT_SUMMARY_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Account Summary Transactions
export function getAccountSummaryTransactions(paramsData, appendWithPreviousList = false) {
  return async dispatch => {
    dispatch({
      type: GET_ACCOUNT_SUMMARY_TRANACTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ACCOUNT_SUMMARY_TRANACTIONS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: moreTransResponse } = response.data;
      if (parseInt(status) === 1) {
        const { data = [], count } = moreTransResponse;
        if (paramsData.EntityTypeId == 2) {
          dispatch({
            type: GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS,
            payload: { lastHundredtransactionsByPurchaseOrders: data },
            totalCount: count,
            appendWithPreviousList: appendWithPreviousList,
            EntityTypeID: paramsData.EntityTypeId
          });
        } else {
          dispatch({
            type: GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS,
            payload: { lastHundredtransactions: data },
            totalCount: count,
            appendWithPreviousList: appendWithPreviousList,
            EntityTypeID: paramsData.EntityTypeId
          });
        }
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ACCOUNT_SUMMARY_TRANACTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Payment Plan Admin
export function getPaymentPlanAdminList(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_PAYMENT_PLAN_ADMIN_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PAYMENT_PLAN_ADMIN_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_PAYMENT_PLAN_ADMIN_LIST_SUCCESS,
          payload: {
            count,
            data
          }
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PAYMENT_PLAN_ADMIN_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Payment Plan Details
export function getPaymentPlanDetail(PaymentPlanId) {
  return async dispatch => {
    dispatch({
      type: GET_PAYMENT_PLAN_DETAIL_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PAYMENT_PLAN_DETAIL_URL,
      headers: authHeader(),
      params: { PaymentPlanId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PAYMENT_PLAN_DETAIL_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PAYMENT_PLAN_DETAIL_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Admin Dashboard
export function getAdminDashboard() {
  return async dispatch => {
    dispatch({
      type: GET_ADMIN_DASHBOARD_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ADMIN_DASHBOARD_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      console.info(initialResponse, 'initial response dasboard');
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_ADMIN_DASHBOARD_SUCCESS,
          payload: initialResponse
        });
      }
      else {
        dispatch({
          type: GET_ADMIN_DASHBOARD_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ADMIN_DASHBOARD_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Products by organization
export function getProductsByOrganization(organizationId) {
  return async dispatch => {
    dispatch({
      type: GET_PRODUCTS_BY_ORGANIZATION_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PRODUCTS_BY_ORGANIZATION_URL,
      headers: authHeader(),
      params: {
        organizationId
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PRODUCTS_BY_ORGANIZATION_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PRODUCTS_BY_ORGANIZATION_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Product
export function getProduct(ProductId, setSelectedProduct, setShowForm) {
  return async dispatch => {
    dispatch({
      type: GET_PRODUCT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PRODUCT_URL,
      headers: authHeader(),
      params: {
        ProductId
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: initialResponse
        });
        setSelectedProduct(ProductId);
        setShowForm(true);

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PRODUCT_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Possible Product Group
export function getPossibleProductGroups() {
  return async dispatch => {
    dispatch({
      type: GET_POSSIBLE_PRODUCT_GROUP_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_POSSIBLE_PRODUCT_GROUP_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_POSSIBLE_PRODUCT_GROUP_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_POSSIBLE_PRODUCT_GROUP_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Document Detail
export function getDocumentDetail(DocumentFormID, DocumentTransactionID = 0) {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_DETAIL_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_DETAIL_URL,
      headers: authHeader(),
      params: {
        DocumentFormID,
        DocumentTransactionID
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_DETAIL_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_DETAIL_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//GetDuesProductSummary
export function getDuesProductSummary(organizationId) {
  return async dispatch => {
    dispatch({
      type: GET_DUES_SUMMARY_LOADING,
    });
    const options = {
      method: 'GET',
      url: `${GET_DUES_PRODUCTSUMMARY_URL}?OrganizationId=${organizationId}`,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DUES_SUMMARY_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DUES_SUMMARY_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Members
export function getMembersList(paramsData, setExportTableList, handleCallBack, exportOpt, columns) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBERS_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBERS_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        if (setExportTableList) {
          setExportTableList(data)
        }
        if (handleCallBack) {
          handleCallBack(exportOpt, columns);
        }
        dispatch({
          type: GET_MEMBERS_LIST_SUCCESS,
          payload: {
            count,
            data
          }
        });

      }
      else if (status == 3) {
        dispatch({
          type: GET_MEMBERS_LIST_ERROR,
          payload: apiResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBERS_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Members
export function getExportMembersList(paramsData, setExportTableList, handleClick) {
  return async dispatch => {
    dispatch({
      type: GET_EXPORT_MEMBERS_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBERS_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        if (setExportTableList) {
          setExportTableList(data)
        }
        if (handleClick) {
          handleClick();
        }
        dispatch({
          type: GET_EXPORT_MEMBERS_LIST_SUCCESS,
          payload: {
            count,
            data
          }
        });

      }
      else if (status == 3) {
        dispatch({
          type: GET_EXPORT_MEMBERS_LIST_ERROR,
          payload: apiResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EXPORT_MEMBERS_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Document Prequisites
export function GetDocumentPrequisites(DocumentFormId) {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_PREQUISITES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ALL_DOCUMENT_PREQUISITES_URL,
      headers: authHeader(),
      params: { DocumentFormId }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_PREQUISITES_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_PREQUISITES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Candidates
export function getCandidatesList(paramsData, callback) {
  return async dispatch => {
    dispatch({
      type: GET_CANDIDATES_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CANDIDATE_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        if (callback) {
          callback(data);
        }
        else {
          dispatch({
            type: GET_CANDIDATES_LIST_SUCCESS,
            payload: {
              count,
              data
            }
          });
        }
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CANDIDATES_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Candidates
export function getExportCandidatesList(paramsData, setExportTableList, handleClick) {
  return async dispatch => {
    dispatch({
      type: GET_EXPORT_CANDIDATES_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CANDIDATE_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        if (setExportTableList) {
          setExportTableList(data)
        }
        if (handleClick) {
          handleClick();
        }
        dispatch({
          type: GET_EXPORT_CANDIDATES_LIST_SUCCESS,
          payload: {
            count,
            data
          }
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EXPORT_CANDIDATES_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Leadership Organizations List
export function getLeadershipOrganizationList(ParentOrganizationID) {
  return async dispatch => {
    dispatch({
      type: GET_LEADERSHIP_ORGANIZATIONS_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_LEADERSHIP_ORGANIZATIONS_LIST_URL,
      headers: authHeader(),
      params: { ParentOrganizationID }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        // const {count,data = []} = apiResponse;
        dispatch({
          type: GET_LEADERSHIP_ORGANIZATIONS_LIST_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_LEADERSHIP_ORGANIZATIONS_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Member Filter Options
export function getMemberFilterOptions(lshipOrganizationID) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_FILTER_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_FILTER_OPTIONS_URL,
      headers: authHeader(),
      params: { lshipOrganizationID }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_MEMBER_FILTER_OPTIONS_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Candidate Filter Options
export function getCandidateFilterOptions() {
  return async dispatch => {
    dispatch({
      type: GET_CANDIDATE_FILTER_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CANDIDATE_FILTER_OPTIONS_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_CANDIDATE_FILTER_OPTIONS_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CANDIDATE_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get addedit event Filter Options
export function getEventFilterOptions(MemberID) {
  return async dispatch => {
    dispatch({
      type: GET_EVENT_FILTER_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EVENT_FILTER_OPTIONS_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_EVENT_FILTER_OPTIONS_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EVENT_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Member Profile
export function GetMemberProfileDetails(MemberID) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_PROFILE_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_PROFILE_DETAILS_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status } = response.data;
      const initialResponse = response.data;
      if (parseInt(status) === 1) {
        // console.log(initialResponse,'initialResponse')
        dispatch({
          type: GET_MEMBER_PROFILE_DETAILS_SUCCESS,
          payload: {
            documents: initialResponse.details.documents,
            profile: initialResponse.details.profile,
            chapterHistory: initialResponse.chapterHistory,
            loginHistory: initialResponse.loginHistory,
            currentChapters: initialResponse.currentChapters,
            positions: initialResponse.positions,
            financialStatus: initialResponse.financialStatus,
            positionAndGroups: initialResponse.positionAndGroups,
            intakeTrained: initialResponse.intakeTrained,
            referenceLetters: initialResponse.referenceLetters,
            ThirdPartyData: initialResponse.ThirdPartyData
          }
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_PROFILE_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Member CalenderEvents
export function GetMemberCalenderEvents(MemberID, history, enqueueSnackbar, setCurrentOrgId, setFilterValues, filterValues) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_CALENDER_EVENT_LOADING,
    });
    const currentOrgId = localStorage.getItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)
    const options = {
      method: 'GET',
      url: GET_MEMBER_DASBOARD_URL,
      headers: authHeader(),
      params: { MemberID, ViewingOrganizationID: currentOrgId != null ? currentOrgId : 0 }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      const { viewingOrganization = {} } = initialResponse
      console.info(status, initialResponse);
      if (parseInt(status) === 1) {
        if (viewingOrganization == null) {
          history.push('/mychapter');
          CustomAlertMessage(`Your Membership is not affiliated with Chapter ${currentOrgId != null ? currentOrgId : 0}`, 'error', enqueueSnackbar);
        } else {
          let orgid = localStorage.getItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)

          // let organizationRec = initialResponse.relatedOrganizations.find(record => record.Primary === true);
          let organizationRec = viewingOrganization;
          // let organization = initialResponse.relatedOrganizations.find(record => record.organizationId === parseInt(orgid));
          let organization = viewingOrganization.organizationId === parseInt(orgid) ? viewingOrganization : undefined;
          if ((Object.keys(viewingOrganization).length === 0) || organizationRec === undefined) {
            localStorage.setItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID, null)
          }
          else if (parseInt(orgid) == 0 || (orgid === null && organizationRec !== undefined) || (organization == undefined)) {
            localStorage.setItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID, organizationRec.organizationId)
            if (setCurrentOrgId)
              setCurrentOrgId(organizationRec.organizationId)
          }
          if (parseInt(orgid) > 0 && setCurrentOrgId) {
            setCurrentOrgId(orgid)
          }
          dispatch({
            type: GET_MEMBER_CALENDER_EVENT_SUCCESS,
            payload: initialResponse
          });
          if (setFilterValues) {
            setFilterValues({ ...filterValues, OrganizationId: organizationRec.leadershipOrganizationId })
          }

        }

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_CALENDER_EVENT_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Candidate Profile
export function GetCandidateProfileDetails(CandidateID, callback) {
  return async dispatch => {
    dispatch({
      type: GET_CANDIDATE_PROFILE_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CANDIDATE_PROFILE_DETAILS_URL,
      headers: authHeader(),
      params: { CandidateID }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
          payload: initialResponse
        });
        callback && callback(initialResponse)
      } else {
        dispatch({
          type: GET_CANDIDATE_PROFILE_DETAILS_ERROR,
          payload: initialResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CANDIDATE_PROFILE_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Purchase Order Details
export function GetPurchaseOrderDetails(PurchaseOrderID) {
  return async dispatch => {
    dispatch({
      type: GET_PURCHASE_ORDER_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PURCHASE_ORDER_DETAILS_URL,
      headers: authHeader(),
      params: { PurchaseOrderID }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PURCHASE_ORDER_DETAILS_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PURCHASE_ORDER_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Payment Plan Admin
export function getRPTPaymentDetails(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_RPT_PAYMENT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_RPT_PAYMENT_DETAILS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_RPT_PAYMENT_DETAILS_SUCCESS,
          payload: {
            count,
            data
          }
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_RPT_PAYMENT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Auto Debit Logs
export function getAutoDebitLogs(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_AUTO_DEBIT_LOGS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_AUTO_DEBIT_LOGS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_AUTO_DEBIT_LOGS_SUCCESS,
          payload: {
            count,
            data
          }
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_AUTO_DEBIT_LOGS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Upcoming Auto Debits
export function getUpcomingAutoDebits(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_UPCOMING_AUTO_DEBITS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_UPCOMING_AUTO_DEBITS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_UPCOMING_AUTO_DEBITS_SUCCESS,
          payload: {
            count,
            data
          }
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_UPCOMING_AUTO_DEBITS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Entity Exam Transactions
export function getEntityExamTransactions(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_ENTITY_EXAM_TRANSACTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ENTITY_EXAM_TRANSACTIONS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, data = {} } = response.data;
      if (status === 'Success') {
        dispatch({
          type: GET_ENTITY_EXAM_TRANSACTIONS_SUCCESS,
          payload: data.response,
          totalCount: 100
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ENTITY_EXAM_TRANSACTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

export const getAdministrativeGroups = (callbackOnResults) => {
  return (dispatch) => {
    API.get(GET_ADMINISTRATIVE_GROUPS_URL, { headers: authHeader() })
      .then((res) => {
        if (res.data.status === 1) {
          callbackOnResults(res.data.response)
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const addMemberToGroup = (data, callbackOnResults) => {
  return (dispatch) => {
    API.post(ADD_MEMBER_TO_GROUP_URL, data, { headers: authHeader() })
      .then((res) => {
        if (res.data.status == 1) {
          callbackOnResults(data.groupId, data.organizationId)
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const addPositionToGroup = (data, callbackOnResults) => {
  return (dispatch) => {
    API.post(ADD_POSITION_TO_GROUP_URL, data, { headers: authHeader() })
      .then((res) => {
        if (res.data.status == 1) {
          callbackOnResults()
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const getAccessList = (groupId, entityTypeId, sourceOwnerID, callbackOnResults) => {
  return (dispatch) => {
    API.get(GET_ACCESS_LIST_URL + 'EntityTypeId=' + entityTypeId + '&SourceOwnerId=' + sourceOwnerID + '&GroupId=' + groupId, { headers: authHeader() })
      .then((res) => {
        if (res.data.status === 1) {
          callbackOnResults(res.data.response)
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const deleteMemberFromGroup = (groupId, memberId, OrganizationId, callbackOnResults, deleteConfirmationToggle) => {
  return (dispatch) => {
    API.delete(DELETE_MEMBER_FROM_GROUP_URL + 'MemberId=' + memberId + '&GroupId=' + groupId + '&OrganizationId=' + OrganizationId, { headers: authHeader() })
      .then((res) => {
        if (res.data.status === 1) {
          if (deleteConfirmationToggle)
            deleteConfirmationToggle()
          callbackOnResults()
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const deletePositionFromGroup = (groupId, positionId, callbackOnResults, deleteConfirmationToggle) => {
  return (dispatch) => {
    API.delete(DELETE_POSITION_FROM_GROUP_URL + 'PositionId=' + positionId + '&GroupId=' + groupId, { headers: authHeader() })
      .then((res) => {
        if (res.data.status === 1) {
          if (deleteConfirmationToggle)
            deleteConfirmationToggle()
          callbackOnResults()
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

//Get Xref Types
export function getXrefTypes(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_XREF_TYPES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_XREF_TYPES_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: xrefTypes } = response.data;
      if (parseInt(status) === 1) {
        const xrefValueExistRecords = xrefTypes.filter(record => record.xrefValueId);
        dispatch({
          type: GET_XREF_TYPES_SUCCESS,
          payload: xrefTypes
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_XREF_TYPES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Xref Types
export function getSettingConfigs(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_SETTING_CONFIGS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SETTING_CONFIGS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: allconfigs } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_SETTING_CONFIGS_SUCCESS,
          payload: allconfigs.allconfigs
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SETTING_CONFIGS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Courses by Current Season
export function getCoursesByCurrentSeason(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_COURSES_BY_CURRENT_SEASON_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COURSES_BY_CURRENT_SEASON_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, data = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_COURSES_BY_CURRENT_SEASON_SUCCESS,
          payload: data.response,
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_COURSES_BY_CURRENT_SEASON_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Courses
export function getCourses() {
  return async dispatch => {
    dispatch({
      type: GET_COURSES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COURSES_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, data = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_COURSES_SUCCESS,
          payload: data,
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_COURSES_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Exam Course
export function getExamByCourse(CourseID) {
  return async dispatch => {
    dispatch({
      type: GET_EXAMS_BY_COURSE_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_Exams_BY_COURSE_URL,
      headers: authHeader(),
      params: { CourseID }
    };
    API(options).then(response => {
      const { status, data = {} } = response.data;
      if (parseInt(data.status) === 1) {
        dispatch({
          type: GET_EXAMS_BY_COURSE_SUCCESS,
          payload: data.response,
        });
      } else {
        dispatch({
          type: GET_EXAMS_BY_COURSE_ERROR,
          payload: data.response
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EXAMS_BY_COURSE_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Officer Report Status
export const getOfficerReportStatus = (organizationId, enqueueSnackbar, handleNextStepWithValidationSteps) => {
  return async dispatch => {
    dispatch({
      type: GET_OFFICER_REPORT_STATUS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_OFFICER_REPORT_STATUS_URL,
      headers: authHeader(),
      params: { OrganizationID: parseInt(organizationId) }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_OFFICER_REPORT_STATUS_SUCCESS,
            payload: initialResponse
          });
          if (handleNextStepWithValidationSteps) {
            handleNextStepWithValidationSteps(initialResponse.Step);
          }
        } else {
          CustomAlertMessage(message, 'error', enqueueSnackbar);
          dispatch({
            type: GET_OFFICER_REPORT_STATUS_ERROR,
            payload: { message: message }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_OFFICER_REPORT_STATUS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Officer Report Status
export const OfficerReportOverride = (documentTransactionId, componentName, handleCallback, setLoading, enqueueSnackbar) => {
  return async dispatch => {

    try {
      setLoading(true);
      const options = {
        method: 'POST',
        url: OVERRIDE_OFFICER_REPORT_STEP_URL,
        headers: authHeader(),
        data: {
          officerReportDocumentTransactionID: parseInt(documentTransactionId),
          stepTitle: componentName
        }
      };
      const { data: apiResponse } = await API(options);
      const { status, response: initialResponse = {}, message = '' } = apiResponse;
      if (parseInt(status) === 1) {
        if (handleCallback) {
          CustomAlertMessage(message, 'success', enqueueSnackbar);
          handleCallback();
        }

      } else {

        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }


    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }



  };
}


//Get Officer Report Status
export const OfficerReportSetStepStatus = (documentTransactionId, stepStatusId, componentName, handleCallback, setLoading, enqueueSnackbar, statusUpdateCallBack) => {
  return async dispatch => {

    try {
      setLoading(true);
      const options = {
        method: 'PUT',
        url: SET_STEP_STATUS_URL,
        headers: authHeader(),
        params: {
          DocumentTransactionID: parseInt(documentTransactionId),
          Status: stepStatusId,
          ComponentName: componentName
        }
      };
      const { data: apiResponse } = await API(options);
      const { status, response: initialResponse = {}, message = '' } = apiResponse;
      if (parseInt(status) === 1) {
        if (stepStatusId == 1) {
          CustomAlertMessage('Please make any changes', 'success', enqueueSnackbar);
        }
        else if (stepStatusId == 2) {
          CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
          handleCallback();
        }
        else if (stepStatusId == 3) {
          CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
          handleCallback();
        }
        else {
          if (statusUpdateCallBack)
            statusUpdateCallBack()
        }
      } else {

        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }


    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }



  };
}

//Get Officer Report Status
export const RosterReportSetStepStatus = (RosterID, stepStatusId, componentName, handleCallback, setLoading, enqueueSnackbar, statusUpdateCallBack) => {
  return async dispatch => {
    try {
      setLoading(true);
      const options = {
        method: 'PUT',
        url: ROSTER_REPORT_SET_STEP_STATUS_URL,
        headers: authHeader(),
        params: {
          RosterID: parseInt(RosterID),
          Status: stepStatusId,
          ComponentName: componentName
        }
      };
      const { data: apiResponse } = await API(options);
      const { status, response: initialResponse = {}, message = '' } = apiResponse;
      if (parseInt(status) === 1) {
        if (stepStatusId == 1) {
          CustomAlertMessage('Please make any changes', 'success', enqueueSnackbar);
        }
        else if (stepStatusId == 2) {
          CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
          handleCallback();
        }
        else if (stepStatusId == 3) {
          CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
          handleCallback();
        }
        else {
          if (statusUpdateCallBack)
            statusUpdateCallBack()
        }
      } else {

        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }


    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }



  };
}

//Get Officer Report Details
export const getOfficerReportDetails = (organizationId) => {
  return async dispatch => {
    dispatch({
      type: GET_OFFICER_REPORT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_OFFICER_REPORT_DETAILS_URL,
      headers: authHeader(),
      params: { OrganizationID: parseInt(organizationId) }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_OFFICER_REPORT_DETAILS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_OFFICER_REPORT_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Officer Report Filter Options
export const getOfficerReportFilterOptions = () => {
  return async dispatch => {
    dispatch({
      type: GET_OFFICER_REPORT_FILTER_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_OFFICER_REPORT_FILTER_OPTIONS_URL,
      headers: authHeader(),
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_OFFICER_REPORT_FILTER_OPTIONS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_OFFICER_REPORT_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Schools
export function getSchoolsList(setTableRec, getFilteredValues, filterValues) {
  return async dispatch => {
    dispatch({
      type: GET_SCHOOLS_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SCHOOL_LIST_URL,
      headers: authHeader(),
      // params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = [] } = response.data;
      if (parseInt(status) === 1) {
        // const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_SCHOOLS_LIST_SUCCESS,
          payload: {
            count: apiResponse.length,
            data: apiResponse
          }
        });
        setTableRec(apiResponse)
        getFilteredValues(filterValues, apiResponse);
      } else {
        dispatch({
          type: GET_SCHOOLS_LIST_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SCHOOLS_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Schools by organizationid
export function getSchoolsListByOrganizationId(OrganizationID, setSchoolRec) {
  return async dispatch => {
    dispatch({
      type: GET_SCHOOLS_LIST_BY_ORGID_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SCHOOL_LIST_BY_ORGID_URL,
      headers: authHeader(),
      params: { OrganizationID }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        // const { count, data = [] } = apiResponse;
        if (setSchoolRec)
          setSchoolRec(apiResponse)
        dispatch({
          type: GET_SCHOOLS_LIST_BY_ORGID_SUCCESS,
          payload: {
            data: apiResponse
          }
        });
      } else {
        dispatch({
          type: GET_SCHOOLS_LIST_BY_ORGID_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SCHOOLS_LIST_BY_ORGID_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get collegeofficial list
export function getCollegeofficialsList(getCollegeFilteredValues, collegeName) {
  return async dispatch => {
    dispatch({
      type: GET_COLLEGEOFFICIAL_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COLLEGE_OFFICIAL_LIST_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        // const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_COLLEGEOFFICIAL_LIST_SUCCESS,
          payload: {
            count: apiResponse.length,
            data: apiResponse
          }
        });
        getCollegeFilteredValues(collegeName, apiResponse)
      } else {
        dispatch({
          type: GET_COLLEGEOFFICIAL_LIST_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SCHOOLS_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get School Profile
export function GetSchoolProfileDetails(schoolID, callback) {
  return async dispatch => {
    dispatch({
      type: GET_SCHOOL_PROFILE_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SCHOOL_PROFILE_DETAILS_URL,
      headers: authHeader(),
      params: { schoolID: parseInt(schoolID) }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_SCHOOL_PROFILE_DETAILS_SUCCESS,
          payload: { schoolDetailRec: initialResponse }
        });
        callback && callback(initialResponse)
      }
      else {
        dispatch({
          type: GET_SCHOOL_PROFILE_DETAILS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SCHOOL_PROFILE_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}




export function GetCollegeOfficialDetails(PersonID) {
  return async dispatch => {
    dispatch({
      type: GET_COLLEGE_OFFICIAL_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COLLEGE_OFFICIAL_DETAILS_URL,
      headers: authHeader(),
      params: { PersonID }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS,
          payload: { CollegeOfficialDetailRec: initialResponse }
        });
        dispatch({
          type: SCHOOL_LOGGED_USER_DETAILS_SUCCESS,
          payload: initialResponse
        });
        // callback && callback(initialResponse)
      }
      else {
        //  console.log(response.data.message,'error message')

        dispatch({
          type: GET_COLLEGE_OFFICIAL_DETAILS_ERROR,
          payload: response.data.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_COLLEGE_OFFICIAL_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


export const deletecollegeofficialrecord = (personID, SchoolId, EntityTypeId, handleIntakeSeasonStatus) => {
  return (dispatch) => {
    API.delete(DELETE_COLLEGE_OFFICIAL_ENTITY_URL + '?SourceOwnerId=' + personID + '&EntityTypeId=' + EntityTypeId + '&SchoolId=' + SchoolId, { headers: authHeader() })
      .then((res) => {
        // console.log(JSON.stringify(res.data))
        if (res.data.status === 1) {
          // callbackOnResults()
          dispatch(GetCollegeOfficialDetails(personID))
          if (handleIntakeSeasonStatus)
            handleIntakeSeasonStatus()

        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

//Get Financial Status
export function getFinancialStatus(MemberID, handleResponsedata, position_id) {
  return async dispatch => {
    dispatch({
      type: GET_FINANCIAL_STATUS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_FINANCIAL_STATUS_URL,
      headers: authHeader(),
      params: { MemberID }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_FINANCIAL_STATUS_SUCCESS,
          payload: response.data.response
        });
        if (handleResponsedata && position_id)
          handleResponsedata(response.data.response, MemberID, position_id)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_FINANCIAL_STATUS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Member Timeline Events
export function GetMemberTimeLInes(paramsData, setExpanded) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_TIMELINE_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_TIMELINE_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      const { PostMessage = [] } = initialResponse
      let data = PostMessage.map((rec, index) => {
        if (rec.postContent.length > 500) {
          return ({ ...rec, expanded: true, isExpandButtonNeded: true })
        } else {
          return ({ ...rec, expanded: false, isExpandButtonNeded: false })

        }
      })
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_MEMBER_TIMELINE_SUCCESS,
          payload: { ...initialResponse, PostMessage: [...data] }
        });


      }
      else {
        dispatch({
          type: GET_MEMBER_TIMELINE_ERROR,
          payload: initialResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_TIMELINE_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Position Types
export function getPositionTypeList() {
  return async dispatch => {
    dispatch({
      type: GET_POSITON_TYPES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_POSTION_TYPES_URL,
      headers: authHeader(),
      // params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: positionValue } = response.data;
      if (parseInt(status) === 1) {
        const positionValueExistRecords = positionValue;
        dispatch({
          type: GET_POSITON_TYPES_SUCCESS,
          payload: positionValueExistRecords
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_POSITON_TYPES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Positions
export function getEntitySelectionList(documentFormId) {
  return async dispatch => {
    dispatch({
      type: GET_ENTITY_SELECTION_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ENTITY_SELECTIONS,
      headers: authHeader(),
      params: { DocumentFormID: documentFormId }
    };
    API(options).then(response => {
      const { status, response: entitySelectionList = [] } = response.data;
      const entitySelectionRecords = entitySelectionList;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_ENTITY_SELECTION_SUCCESS,
          payload: entitySelectionRecords
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ENTITY_SELECTION_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Positions
export function getPositionList() {
  return async dispatch => {
    dispatch({
      type: GET_POSITONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_POSTIONS_URL,
      headers: authHeader(),
      // params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: positionValue } = response.data;
      if (parseInt(status) === 1) {
        const positionValueExistRecords = positionValue;
        dispatch({
          type: GET_POSITONS_SUCCESS,
          payload: positionValueExistRecords
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_POSITONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get DocumentForms
export function getDocumentForms() {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORMS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_FORMS_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORMS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORMS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Approvalsteps
export function getApprovalSteps(DocumentFormID) {
  return async dispatch => {
    dispatch({
      type: GET_APPROVAL_STEPS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_APPROVAL_STEPS_URL,
      headers: authHeader(),
      params: {
        DocumentFormID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_APPROVAL_STEPS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_APPROVAL_STEPS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get DocumentFormTypes
export function getDocumentFormTypes() {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORM_TYPES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_FORM_TYPES_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORM_TYPES_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORM_TYPES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get DocumentForm Filters
export function getDocumentFormFilters() {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORM_FILTERS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_FORM_FILTER_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORM_FILTERS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORM_FILTERS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Approvalstep by ID
export function getApprovalStepByID(ApprovalStepID) {
  return async dispatch => {
    dispatch({
      type: GET_APPROVAL_STEP_BY_ID_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_APPROVAL_STEP_BY_ID_URL,
      headers: authHeader(),
      params: {
        ApprovalStepID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_APPROVAL_STEP_BY_ID_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_APPROVAL_STEP_BY_ID_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Divisions
export function getDivisions() {
  return async dispatch => {
    dispatch({
      type: GET_DIVISIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DIVISIONS_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DIVISIONS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DIVISIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Approval Step Status
export function getApprovalStepStatusList() {
  return async dispatch => {
    dispatch({
      type: GET_APPROVAL_STEP_STATUS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_APPROVAL_STEP_STATUS_LIST_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_APPROVAL_STEP_STATUS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_APPROVAL_STEP_STATUS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Document Form By ID
export function getDocumentFormByID(DocumentFormID, DocumentTransactionID = 0) {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORM_BY_ID_LOADING,
      payload: {}
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_DETAIL_URL,
      headers: authHeader(),
      params: {
        DocumentFormID,
        DocumentTransactionID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORM_BY_ID_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORM_BY_ID_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Document Form
export function getDocumentForm(DocumentFormID, DocumentTransactionID = 0) {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORMBYENTITY_LOADING,
      payload: {}
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_DETAIL_URL,
      headers: authHeader(),
      params: {
        DocumentFormID,
        DocumentTransactionID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORMBYENTITY_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORMBYENTITY_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Document Form By ID
export function getDocumentFormByEntity(DocumentFormID, EntityTypeID, SourceOwnerID, handleOnClickSign) {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FORMBYENTITY_LOADING,
      payload: {}
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_FORMBYENTITY_URL,
      headers: authHeader(),
      params: {
        DocumentFormID,
        EntityTypeID,
        SourceOwnerID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FORMBYENTITY_SUCCESS,
          payload: data
        });
        if (handleOnClickSign) {
          handleOnClickSign(data)
        }
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FORMBYENTITY_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Approvalsteps
export function getSignerPositions(ApprovalStepID) {
  return async dispatch => {
    dispatch({
      type: GET_SIGNER_POSITIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SIGNER_POSITIONS_URL,
      headers: authHeader(),
      params: {
        ApprovalStepID
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_SIGNER_POSITIONS_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SIGNER_POSITIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Approvalsteps
export function getSignerPositionTypes(ApprovalStepID) {
  return async dispatch => {
    dispatch({
      type: GET_SIGNER_POSITION_TYPE_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SIGNER_POSITIONTYPES_URL,
      headers: authHeader(),
      params: {
        ApprovalStepID: parseInt(ApprovalStepID)
      }
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_SIGNER_POSITION_TYPE_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SIGNER_POSITION_TYPE_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Position Details
export function GetPositionDetails(positionID, callback) {
  return async dispatch => {
    dispatch({
      type: GET_POSITON_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_POSTION_DETAILS_URL,
      headers: authHeader(),
      params: { positionID: parseInt(positionID) }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_POSITON_DETAILS_SUCCESS,
          payload: { PositionDetailRec: initialResponse }
        });
        callback && callback(initialResponse)
      }
      else {
        dispatch({
          type: GET_POSITON_DETAILS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_POSITON_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Position Filters
export function getPositionFilters() {
  return async dispatch => {
    dispatch({
      type: GET_POSITON_FILTER_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_POSTION_FILTER_URL,
      headers: authHeader(),
      // params: { ...paramsData }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_POSITON_FILTER_SUCCESS,
          payload: response.data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_POSITON_FILTER_ERROR,
            payload: data.message
          });
        }
      })
    // .finally(() => {
    // //   if (callback)
    // //     callback(null, false)
    // // }
    // );
  };
}


//Get events
export const GetEventList = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_EVENT_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EVENT_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options)
      .then(response => {
        const { status, response: apiResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          const { count = 0, data = [] } = apiResponse;
          dispatch({
            type: GET_EVENT_LIST_SUCCESS,
            payload: {
              count: count,
              data: data
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EVENT_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}





//Get Event Details
export function GetEventDetails(EventId, handleShowForm) {
  return async dispatch => {
    dispatch({
      type: GET_EVENT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EVENT_DETAILS_URL,
      headers: authHeader(),
      params: { EventId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_EVENT_DETAILS_SUCCESS,
          payload: { eventDetails: initialResponse }
        });
        if (handleShowForm)
          handleShowForm(EventId, initialResponse);

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EVENT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get ticketss
export const GetTicketList = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_TICKET_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_TICKET_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options)
      .then(response => {
        const { status, response: apiResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_TICKET_LIST_SUCCESS,
            payload: {
              count: apiResponse.length,
              data: apiResponse
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_TICKET_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Ticket  Details
export function GetTicketDetails(TicketId) {
  return async dispatch => {
    dispatch({
      type: GET_TICKET_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_TICKET_DETAILS_URL,
      headers: authHeader(),
      params: { TicketId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_TICKET_DETAILS_SUCCESS,
          payload: { ticketDetails: initialResponse }
        });
        // handleShowForm(EventId,initialResponse);
        // console.log('selectedEventDetails',initialResponse)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_TICKET_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Ticket  filters
export function GetTicketFilters(EventId) {
  return async dispatch => {
    dispatch({
      type: GET_TICKET_FILTER_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_TICKET_FILTER_URL,
      headers: authHeader(),
      params: { EventId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_TICKET_FILTER_SUCCESS,
          payload: { ticketfilters: response.data }
        });
        // handleShowForm(EventId,initialResponse);
        // console.log('selectedEventDetails',response.data)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_TICKET_FILTER_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Ticket  Fee Determine
export function GetTransactionFee(chargeAmount, feeTypeID, payType, cardType, setTotal) {
  return async dispatch => {
    dispatch({
      type: GET_FEE_DETERMINE_LOADING,
    });
    const options = {
      method: 'POST',
      url: GET_FEE_DETERMINE_URL,
      headers: authHeader(),
      data:
      {
        feeTypeID,
        chargeAmount,
        payType,
        cardType
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_FEE_DETERMINE_SUCCESS,
          payload: { transactionFeeRecord: initialResponse }
        });
        if (setTotal) {
          setTotal(parseFloat(chargeAmount) + parseFloat(initialResponse.amount))
        }
        // handleShowForm(EventId,initialResponse);
        // console.log('selectedEventDetails',initialResponse)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_FEE_DETERMINE_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Event Details
export function GetCalenderEventDetails(EventId) {
  return async dispatch => {
    dispatch({
      type: GET_CALENDER_EVENT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CALENDER_EVENT_DETAILS_URL,
      headers: authHeader(),
      params: { EventId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_CALENDER_EVENT_DETAILS_SUCCESS,
          payload: { calenderEventDetails: initialResponse }
        });
        // handleShowForm(EventId,initialResponse);
        // console.log('selectedEventDetails',initialResponse)
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CALENDER_EVENT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Officer Report Status
export const getEventCheckoutDetails = (eventId) => {
  return async dispatch => {
    dispatch({
      type: GET_EVENT_CHECKOUT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EVENT_CHECKOUT_DETAILS_API,
      headers: authHeader(),
      params: { EventID: parseInt(eventId) }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_EVENT_CHECKOUT_DETAILS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EVENT_CHECKOUT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Required documents
export const GetRequiredDocumentList = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_REQUIRED_DOCUMENT_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_REQUIRED_DOCUMENT_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          const { count, data = [] } = initialResponse;
          dispatch({
            type: GET_REQUIRED_DOCUMENT_LIST_SUCCESS,
            payload: {
              count,
              data
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_REQUIRED_DOCUMENT_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}






//Get documents
export const GetDocumentList = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          const { count, data = [] } = initialResponse;
          dispatch({
            type: GET_DOCUMENT_LIST_SUCCESS,
            payload: {
              count,
              data
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get documents Filter Options
export function getdocumentFilterOptions() {
  return async dispatch => {
    dispatch({
      type: GET_DOCUMENT_FILTER_OPTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_DOCUMENT_FILTER_OPTIONS_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DOCUMENT_FILTER_OPTIONS_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DOCUMENT_FILTER_OPTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get IntakeSeason
export const GetIntakeSeasonSteps = (paramsData, handleActiveStep, setShowPlanningDocument, showPlanningDocument) => {
  return async dispatch => {
    dispatch({
      type: GET_INTAKE_SEASON_STEPS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_INTAKE_SEASON_STATUS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          if (paramsData.DivisionID == 1) {

            dispatch({
              type: GET_INTAKE_SEASON_STEPS_SUCCESS,
              payload: { nationalintakeseasondata: initialResponse }
            });
          }
          if (paramsData.DivisionID == 2) {

            dispatch({
              type: GET_INTAKE_SEASON_STEPS_SUCCESS,
              payload: { regionalintakeseasondata: initialResponse }
            });
          }
          if (paramsData.DivisionID == 3) {

            dispatch({
              type: GET_INTAKE_SEASON_STEPS_SUCCESS,
              payload: { localintakeseasondata: initialResponse }
            });
          }
          if (handleActiveStep)
            handleActiveStep(paramsData.DivisionID == 1 || paramsData.DivisionID == 3 ? initialResponse.Steps : initialResponse.steps)
          if (showPlanningDocument)
            setShowPlanningDocument(true)
        }
        else {
          dispatch({
            type: GET_INTAKE_SEASON_STEPS_ERROR,
            payload: response.data.message
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INTAKE_SEASON_STEPS_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get National IntakeSeason
export const GetNationalIntakeSeasonSteps = (paramsData, handleActiveStep, setShowPlanningDocument, showPlanningDocument) => {
  return async dispatch => {
    dispatch({
      type: GET_INTAKE_SEASON_STEPS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_NATIONAL_INTAKE_SEASON_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_INTAKE_SEASON_STEPS_SUCCESS,
            payload: { nationalintakeseasondata: initialResponse }
          });

          if (handleActiveStep)
            handleActiveStep(initialResponse.Steps)
          if (showPlanningDocument)
            setShowPlanningDocument(true)
        }
        else {
          dispatch({
            type: GET_INTAKE_SEASON_STEPS_ERROR,
            payload: { message }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INTAKE_SEASON_STEPS_ERROR,
            payload: data.message
          });
        }
      });
  };
}






//Set addMemberToRosterReport
export const ReviewaddMemberToRosterReport = (organizationId, MemberIDs, isOfficerReport = false, callBack, enqueueSnackbar, componentName, setLoading, handleSuccessCallBack, setCount, setTotalRecord, index, totalrec) => {
  return async dispatch => {

    const dataToStore = {
      organizationId: parseInt(organizationId),
      members: MemberIDs,
      ResponseStep: isOfficerReport ? componentName : ''
    };
    try {
      setLoading(true);
      const options = {
        url: ADD_MEMBER_TO_ROSTER_REPORT,
        method: 'POST',
        headers: authHeader(),
        data: dataToStore
      };
      const { data: apiResponse } = await API(options);
      const { status, message = '', response = {} } = apiResponse;
      const { results = [] } = response;
      if (parseInt(status) === 1) {
        if (isOfficerReport) {

          let filterdata = results.filter(x => x.status == 1);
          if (parseInt(filterdata.length) === parseInt(results.length)) {
            CustomAlertMessage(message, 'success', enqueueSnackbar);
          }
          if (setCount) {
            setCount(index + 1)
          }
          if (setTotalRecord) {
            setTotalRecord(totalrec.length)
          }
          dispatch({
            type: GET_MEMBERS_LIST_SUCCESS,
            payload: {
              count: 0,
              data: []
            }
          });
          dispatch({
            type: SET_OFFICER_REPORT_STEP,
            payload: {
              componentName: response.detail.componentName,
              datax: response.detail
            }
          });

          handleSuccessCallBack(response);
        }
        else {
          CustomAlertMessage(message, 'success', enqueueSnackbar);
          if (callBack)
            callBack()
        }

      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
};


//Set addMemberToRosterReport
export const addMemberToRosterReport = (organizationId, MemberIDs, isOfficerReport = false, callBack, enqueueSnackbar, componentName, setFormLoading, handleSuccessCallBack) => {
  return async dispatch => {
    const dataToStore = {
      organizationId: parseInt(organizationId),
      members: MemberIDs,
      ResponseStep: isOfficerReport ? componentName : ''
    };
    try {
      setFormLoading(true);
      const options = {
        url: ADD_MEMBER_TO_ROSTER_REPORT,
        method: 'POST',
        headers: authHeader(),
        data: dataToStore
      };
      const { data: apiResponse } = await API(options);
      const { status, message = '', response = {} } = apiResponse;
      const { results = [] } = response;
      if (parseInt(status) === 1) {
        if (isOfficerReport) {
          handleSuccessCallBack(response);
          let filterdata = results.filter(x => x.status == 1);
          if (parseInt(filterdata.length) === parseInt(results.length)) {
            CustomAlertMessage(message, 'success', enqueueSnackbar);
          }
          dispatch({
            type: GET_MEMBERS_LIST_SUCCESS,
            payload: {
              count: 0,
              data: []
            }
          });
          dispatch({
            type: SET_OFFICER_REPORT_STEP,
            payload: {
              componentName: response.detail.componentName,
              datax: response.detail
            }
          });

        }
        else {
          CustomAlertMessage(message, 'success', enqueueSnackbar);
          if (callBack)
            callBack(response)
        }

      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setFormLoading(false);
    }
  };
};

export const handleRemoveMemberFromRoster = (organizationId, MemberIDs, componentName, setButtonLoading, setConDigFlag, conDigFlag, handleSuccessCallBack, enqueueSnackbar) => {
  return async dispatch => {

    const dataToStore = {
      organizationId: parseInt(organizationId),
      members: MemberIDs,
      ResponseStep: componentName,
    };
    try {
      setButtonLoading(true);
      const options = {
        url: MEMBER_FROMROSTER_REPORT_URL,
        method: 'POST',
        data: dataToStore,
        headers: authHeader()
      };
      const { data: apiResponse } = await API(options);
      const { status, response = {} } = apiResponse;
      if (parseInt(status) === 1) {
        CustomAlertMessage('Record removed successfully', 'success', enqueueSnackbar);
        // dispatch(GetTicketList({ EventId: event_Id }))
        if (handleSuccessCallBack) {
          handleSuccessCallBack(response)
        }
        setConDigFlag(!conDigFlag);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setButtonLoading(false);
    }
  };

}
//Get sign Queue
export const getSignQueueByID = (DocumentTransactionID) => {
  return async dispatch => {
    dispatch({
      type: GET_SIGNQUEUE_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_SIGN_QUEUE_URL,
      headers: authHeader(),
      params: { DocumentTransactionID }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_SIGNQUEUE_LIST_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_SIGNQUEUE_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Exam Transaction Result
export const getExamTransactionResultByID = (ExamTransactionID) => {
  return async dispatch => {
    dispatch({
      type: GET_EXAM_TRANSACTION_RESULT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EXAMTRANSACTIONRESULT_URL,
      headers: authHeader(),
      params: { ExamTransactionID }
    };
    API(options)
      .then(response => {
        const { status, data = [] } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_EXAM_TRANSACTION_RESULT_SUCCESS,
            payload: data
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EXAM_TRANSACTION_RESULT_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Exam Sections
export const getExamSections = (ExamID) => {
  return async dispatch => {
    dispatch({
      type: GET_EXAM_SECTIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_EXAM_SECTIONS_URL,
      headers: authHeader(),
      params: { ExamID }
    };
    API(options)
      .then(response => {
        const { status, data = [] } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_EXAM_SECTIONS_SUCCESS,
            payload: data
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_EXAM_SECTIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Planning Document List
export function getPlanningDocumentList() {
  return async dispatch => {
    dispatch({
      type: GET_PLANNING_DOCUMENT_EVENT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PLANNING_DOCUMENT_EVENT_LIST_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: positionValue } = response.data;
      if (parseInt(status) === 1) {
        const positionValueExistRecords = positionValue;
        dispatch({
          type: GET_PLANNING_DOCUMENT_EVENT_SUCCESS,
          payload: positionValueExistRecords
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENT_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Planning Document Details
export function GetPlanningDocumentDetails(OrganizationEventTypeID) {
  return async dispatch => {
    dispatch({
      type: GET_PLANNING_DOCUMENT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PLANNING_DOCUMENT_EVENT_DETAILS_URL,
      headers: authHeader(),
      params: { OrganizationEventTypeID: parseInt(OrganizationEventTypeID) }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PLANNING_DOCUMENT_DETAILS_SUCCESS,
          payload: { PlanningDocumentDetailRec: initialResponse }
        });
        // callback && callback(initialResponse)
      }
      else {
        dispatch({
          type: GET_PLANNING_DOCUMENT_DETAILS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PLANNING_DOCUMENT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Planning Document Filters
export function GetPlanningDocumentFilter() {
  return async dispatch => {
    dispatch({
      type: GET_PLANNING_DOCUMENT_FILTERS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PLANNING_DOCUMENT_EVENT_FILTERS_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_PLANNING_DOCUMENT_FILTERS_SUCCESS,
          payload: { PlanningDocumentFilterRec: initialResponse }
        });
        // callback && callback(initialResponse)
      }
      else {
        dispatch({
          type: GET_PLANNING_DOCUMENT_FILTERS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PLANNING_DOCUMENT_FILTERS_ERROR,
            payload: data.message
          });
        }
      });
  };
}



export function GetOrganizationDetailsforprospect(organizationId) {
  return async dispatch => {
    dispatch({
      type: GET_ORGANIZATION_DETAILS_FOR_PROSPECT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ORGANIZATION_DETAIL_FOR_PROSPECT_API,
      headers: authHeader(),
      params: { organizationId }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      // console.log(initialResponse, 'initialResponse')
      if (parseInt(status) === 1) {
        // const orgid = initialResponse.organizationID || 0

        // localStorage.setItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID, "initialResponse.organizationID");
        dispatch({
          type: GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS,
          payload: { data: initialResponse }
        });

      }
      else {

        dispatch({
          type: GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR,
          payload: response.data.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Planning Document Event Check in
export const GetPlanningDocumentEventCheckin = (EventID, OrgID) => {
  return async dispatch => {
    dispatch({
      type: GET_PLANNING_DOCUMENT_EVENT_CHECKIN_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PLANNING_DOCUMENT_EVENT_CHECKIN_API,
      // headers: authHeader(),
      params: { EventID, OrganizationID: parseInt(OrgID), entityTypeId: getLoggedEntityTypeID(), sourceOwnerID: getLoggedSourceOwnerID() }
    };

    API(options)
      .then(response => {
        const { status, message = '', response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          // const { count, data = [] } = initialResponse;

          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENT_CHECKIN_SUCCESS,
            payload: { ...initialResponse }
          });


        }
        else {
          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENT_CHECKIN_ERROR,
            payload: response.data.message
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENT_CHECKIN_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Application status
export const GetProspectsApplicationStatus = (paramsData, enqueueSnackbar, handledefaultActiveStep) => {
  return async dispatch => {
    dispatch({
      type: GET_PROSPECT_APPLICATION_STATUS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PROSPECT_APPLICATION_STATUS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message: errormessage = '' } = response.data;
        const { status: insideStatus = 0, message = '', steps = [] } = initialResponse
        if (parseInt(status) === 1) {
          if (handledefaultActiveStep) {
            handledefaultActiveStep(steps)
          }
          dispatch({
            type: GET_PROSPECT_APPLICATION_STATUS_SUCCESS,
            payload: { data: initialResponse }
          });
        } else {
          CustomAlertMessage(errormessage, 'error', enqueueSnackbar);
          dispatch({
            type: GET_PROSPECT_APPLICATION_STATUS_ERROR,
            payload: { message: errormessage }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PROSPECT_APPLICATION_STATUS_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get Prospects details
export const GetProspectsdetails = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_PROSPECT_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PROSPECT_DETAILS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };

    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_PROSPECT_DETAILS_SUCCESS,
            payload: { data: initialResponse }
          });
        }
        else {
          dispatch({
            type: GET_PROSPECT_DETAILS_ERROR,
            payload: message
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PROSPECT_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get MembershipApplicationSetting
export function getMembershipApplicationSetting() {
  return async dispatch => {
    dispatch({
      type: GET_MEMBERSHIP_APPLICATION_SETTING_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBERSHIP_APPLICATION_SETTING_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_MEMBERSHIP_APPLICATION_SETTING_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBERSHIP_APPLICATION_SETTING_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Questions by Section
export function getQuestionsBySection(sectionID) {
  return async dispatch => {
    dispatch({
      type: GET_QUESTIONS_BY_SECTION_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_QUESTIONS_BY_SECTION_URL,
      headers: authHeader(),
      params: { SectionID: parseInt(sectionID) }
    };
    API(options).then(response => {
      const { status, data } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_QUESTIONS_BY_SECTION_SUCCESS,
          payload: data
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_QUESTIONS_BY_SECTION_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Reference Letter
export function getReferenceLetterList(setTableRec, memberId) {
  return async dispatch => {
    dispatch({
      type: GET_REFERENCE_LETTER_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_REFERENCE_LETTER_LIST_URL,
      headers: authHeader(),
      params: { MemberID: memberId }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        // const { count, data = [] } = apiResponse;
        dispatch({
          type: GET_REFERENCE_LETTER_LIST_SUCCESS,
          payload: {
            count: apiResponse.length,
            data: apiResponse
          }
        });
        // setTableRec(apiResponse.references)
        setTableRec(apiResponse)
      } else {
        dispatch({
          type: GET_REFERENCE_LETTER_LIST_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_REFERENCE_LETTER_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get ApplicationRequiredDocument
export function getApplicationRequiredDocument() {
  return async dispatch => {
    dispatch({
      type: GET_APPLICATION_REQUIRED_DOCUMENT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_APPLICATION_REQUIRED_DOCUMENT_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: apiResponse } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_APPLICATION_REQUIRED_DOCUMENT_SUCCESS,
          payload: apiResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_REFERENCE_LETTER_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get latest approved  Officer Report
export const getLatestApprovedOfficerReport = (organizationId) => {
  return async dispatch => {
    dispatch({
      type: GET_LATEST_APPROVED_OFFICER_REPORT_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_LATEST_APPROVED_OFFICER_REPORT_URL,
      headers: authHeader(),
      params: { OrganizationID: parseInt(organizationId) }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_LATEST_APPROVED_OFFICER_REPORT_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_LATEST_APPROVED_OFFICER_REPORT_ERROR,
            payload: data.message
          });
        }
      });
  };
}

export const GetPlanningDocumentEvents = (DocumentTransactionID) => {
  return async dispatch => {
    dispatch({
      type: GET_PLANNING_DOCUMENT_EVENTS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_PLANNING_DOCUMENT_EVENTS_API,
      headers: authHeader(),
      params: { DocumentTransactionID }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = [] } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENTS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PLANNING_DOCUMENT_EVENTS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

export const GetChapterInterviews = (OrganizationID) => {
  return async dispatch => {
    dispatch({
      type: GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CHAPTER_INTERVIEWS_URL,
      headers: authHeader(),
      params: { OrganizationID }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = [] } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

export const GetInvitationRequests = (MemberID, OrganizationID) => {
  console.info(MemberID, OrganizationID);
  return async dispatch => {
    dispatch({
      type: GET_INVITATION_REQUESTS_LOADING,
    });
    let paramsData = {};
    if (MemberID !== null) {
      paramsData = {
        RequestingMemberID: parseInt(MemberID)
      };
    } else {
      paramsData = {
        OrganizationID: parseInt(OrganizationID)
      };
    }
    const options = {
      method: 'GET',
      url: (MemberID !== null) ? GET_INVITATION_REQUESTS_URL : GET_INVITATION_REQUESTS_BY_ORGANIZATION_URL,
      headers: authHeader(),
      params: paramsData
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = [] } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_INVITATION_REQUESTS_SUCCESS,
            payload: initialResponse
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INVITATION_REQUESTS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Region List
export function GetAllRegionDetails() {
  return async dispatch => {
    dispatch({
      type: GET_REGIONS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_REGIONS_LIST_URL,
      headers: authHeader(),
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_REGIONS_SUCCESS,
          payload: {
            data: apiResponse,
            count: apiResponse.length
          }
        });
      } else {
        dispatch({
          type: GET_REGIONS_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_REGIONS_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Get masterIntake details
export function GetmasterIntakedtails(DocumentTransactionID) {
  return async dispatch => {
    dispatch({
      type: GET_MASTERINTAKE_DETAILS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MASTERINTAKE_DETAILS_API,
      headers: authHeader(),
      params: { DocumentTransactionID }
    };
    API(options).then(response => {
      const { status, response: responsedata } = response.data;
      const initialResponse = response.data;
      if (parseInt(status) === 1) {
        // console.log(initialResponse,'initialResponse')
        dispatch({
          type: GET_MASTERINTAKE_DETAILS_SUCCESS,
          payload: { details: responsedata }
        });
      } else {
        dispatch({
          type: GET_MASTERINTAKE_DETAILS_ERROR,
          payload: initialResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MASTERINTAKE_DETAILS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//get CollegeOfficial School History
export function getCollegeOfficialSchoolHistory(SchoolId) {
  return async dispatch => {
    dispatch({
      type: GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_COLLEGE_OFFICIAL_SCHOOL_HISTORY,
      headers: authHeader(),
      params: { SchoolId }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        // const { count, data = [] } = apiResponse;

        dispatch({
          type: GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_SUCCESS,
          payload: {
            data: apiResponse
          }
        });
      } else {
        dispatch({
          type: GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_ERROR,
          payload: apiResponse.message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get events
export const GetPurchaseOrderList = (paramsData) => {
  return async dispatch => {
    dispatch({
      type: GET_PURCHASE_ORDER_LIST_LOADING,
    });
    console.info(paramsData, 'paramsData')
    const options = {
      method: 'GET',
      url: GET_PURCHASE_ORDERS_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options)
      .then(response => {
        const { status, response: apiResponse = {} } = response.data;
        if (parseInt(status) === 1) {
          const { count = 0, data = [] } = apiResponse;
          dispatch({
            type: GET_PURCHASE_ORDER_LIST_SUCCESS,
            payload: {
              count: count,
              data: data
            }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_PURCHASE_ORDER_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Get Intake Season
export function getAllIntakeSeason() {
  return async dispatch => {
    dispatch({
      type: GET_INTAKE_SEASON_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ALL_INTAKE_SEASON_URL,
      headers: authHeader(),
      // params: { MemberID }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_INTAKE_SEASON_SUCCESS,
          payload: response.data.response
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INTAKE_SEASON_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//GetCancelRefundpayment
export const GetCancelRefundpayment = (PurchaseOrderID, callBack, setLoading, enqueueSnackbar) => {
  return async dispatch => {

    try {
      setLoading(true);
      const options = {
        method: 'POST',
        url: CANCEL_REFUND_PAYMENT_URL,
        headers: authHeader(),
        params: {
          PurchaseOrderID: parseInt(PurchaseOrderID),

        }
      };
      const { data: apiResponse } = await API(options);
      const { status, response: initialResponse = {}, message = '' } = apiResponse;
      if (parseInt(status) === 1) {

        CustomAlertMessage(message, 'success', enqueueSnackbar);
        callBack();

      } else {

        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }


    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }



  };
}



//Get Chapter roster activity
export function getChapterRosterActivityList(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_CHAPTER_ACTIVITY_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_CHAPTER_ACTIVITY_BY_CATEGORYID_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = [], message = '' } = response.data;
      if (parseInt(status) === 1) {
        console.info({ activity: apiResponse }, 'apiResponse')
        dispatch({
          type: GET_CHAPTER_ACTIVITY_SUCCESS,
          payload: { activity: apiResponse }
        });
      } else {
        dispatch({
          type: GET_CHAPTER_ACTIVITY_ERROR,
          payload: message
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_CHAPTER_ACTIVITY_ERROR,
            payload: data.message
          });
        }
      })
  };
}



//Get Hard List
export function getHardList(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_HARD_LIST_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_HARD_LIST_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {

        dispatch({
          type: GET_HARD_LIST_SUCCESS,
          payload: {
            count: apiResponse.length,
            data: apiResponse
          }
        });
      }
      else if (status == 3) {
        dispatch({
          type: GET_HARD_LIST_ERROR,
          payload: apiResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_HARD_LIST_ERROR,
            payload: data.message
          });
        }
      });
  };
}




//Accounts List
export function getMemberManagedEntities(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_MANAGED_ENTITIES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_MANAGED_ENTITIES_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {} } = response.data;
      if (parseInt(status) === 1) {

        dispatch({
          type: GET_MEMBER_MANAGED_ENTITIES_SUCCESS,
          payload: { ...apiResponse }

        });
      }
      else {
        dispatch({
          type: GET_MEMBER_MANAGED_ENTITIES_ERROR,
          payload: apiResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_MANAGED_ENTITIES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

// GetIntakePaymentSummary
export const GetIntakePaymentSummary = (payload) => {
  return async dispatch => {
    dispatch({
      type: GET_INTAKE_PAYMENT_SUMMARY_LOADING,
    });
    const options = {
      url: GET_INTAKE_PAYMENT_SUMMARY_URL,
      method: 'POST',
      data: payload,
      headers: authHeader(),
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_INTAKE_PAYMENT_SUMMARY_SUCCESS,
            payload: initialResponse
          });
        }
        else {
          dispatch({
            type: GET_INTAKE_PAYMENT_SUMMARY_ERROR,
            payload: message
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INTAKE_PAYMENT_SUMMARY_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//GetChapterDuesSummary
export function GetChapterDuesSummary(OrganizationID, IncludeNational) {
  return async dispatch => {
    if (IncludeNational == false) {
      dispatch({
        type: GET_CHAPTER_DUES_SUMMARY_LOADING,
      });
    } else {
      dispatch({
        type: GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_LOADING,
      });
    }
    const options = {
      method: 'GET',
      url: CHAPTER_DUES_SUMMARY_URL,
      headers: authHeader(),
      params: { OrganizationID, IncludeNational }
    };
    API(options).then(response => {
      const { status, message = '' } = response.data;
      if (IncludeNational) {
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_SUCCESS,
            payload: response.data.Data
          });

        } else {
          dispatch({
            type: GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR,
            payload: message
          });
        }
      } else {
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_CHAPTER_DUES_SUMMARY_SUCCESS,
            payload: response.data.Data
          });

        } else {
          dispatch({
            type: GET_CHAPTER_DUES_SUMMARY_ERROR,
            payload: message
          });
        }
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          if (IncludeNational == false) {
            dispatch({
              type: GET_CHAPTER_DUES_SUMMARY_ERROR,
              payload: data.message
            });
          }
          else {
            dispatch({
              type: GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR,
              payload: data.message
            });
          }
        }
      });
  };
}

// GetFinanceProductTransportReport
export const GetFinanceProductTransportReport = (payload) => {
  return async dispatch => {
    dispatch({
      type: GET_FINANCE_PRODUCT_TRANSPORT_REPORT_LOADING,
    });
    const options = {
      url: GET_FINANCE_PRODUCT_TRANSPORT_REPORT_URL,
      method: 'POST',
      data: payload,
      headers: authHeader(),
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        if (parseInt(status) === 1) {
          dispatch({
            type: GET_FINANCE_PRODUCT_TRANSPORT_REPORT_SUCCESS,
            payload: initialResponse
          });
        }
        else {
          dispatch({
            type: GET_FINANCE_PRODUCT_TRANSPORT_REPORT_ERROR,
            payload: message
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_FINANCE_PRODUCT_TRANSPORT_REPORT_ERROR,
            payload: data.message
          });
        }
      });
  };
}



//Accounts List
export function getFinanceReportingFilter(paramsData) {
  return async dispatch => {
    dispatch({
      type: GET_FINANCE_REPORTING_FILTER_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_FINANCE_REPORTING_FILTER_URL,
      headers: authHeader(),
      params: { ...paramsData }
    };
    API(options).then(response => {
      const { status, response: apiResponse = {}, message = '' } = response.data;
      if (parseInt(status) === 1) {

        dispatch({
          type: GET_FINANCE_REPORTING_FILTER_SUCCESS,
          payload: { ...apiResponse }

        });
      }
      else {
        dispatch({
          type: GET_FINANCE_REPORTING_FILTER_ERROR,
          payload: message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_FINANCE_REPORTING_FILTER_ERROR,
            payload: data.message
          });
        }
      });
  };
}


//Get Upcoming Fiscal Year Details
export function getUpcomingFiscalYearDetails(organizationId) {
  return async dispatch => {
    dispatch({
      type: UPCOMING_FISCAL_YEAR_DETAIL_LOADING,
    });
    const options = {
      method: 'GET',
      url: UPCOMING_FISCAL_YEAR_DETAIL_URL,
      headers: authHeader(),
      params: { organizationId }
    };
    API(options).then(response => {
      const { status, response: data = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: UPCOMING_FISCAL_YEAR_DETAIL_SUCCESS,
          payload: data
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: UPCOMING_FISCAL_YEAR_DETAIL_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Invites
export function getInvites(OrganizationRosterID) {
  return async dispatch => {
    dispatch({
      type: GET_INVITES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_INVITES_URL,
      headers: authHeader(),
      params: { OrganizationRosterID }
    };
    API(options).then(response => {
      const { status, response: data = [] } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_INVITES_SUCCESS,
          payload: data
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_INVITES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//GetQuickLinks
export function GetQuickLinks(accessLevel) {
  return async dispatch => {
    dispatch({
      type: GET_QUICK_LINKS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ADMIN_DASHBOARD_QUICKLINKS_URL,
      headers: authHeader(),
      params: {
        AccessLevel: accessLevel
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_QUICK_LINKS_SUCCESS,
          payload: initialResponse
        });
      }
      else {
        dispatch({
          type: GET_QUICK_LINKS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_QUICK_LINKS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//GetDashboardTabs
export function GetDashboardTabs(accessLevel) {
  return async dispatch => {
    dispatch({
      type: GET_DASHBOARD_TABS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ADMIN_DASHBOARD_TABS_URL,
      headers: authHeader(),
      params: {
        AccessLevel: accessLevel
      }
    };
    API(options).then(response => {
      const { status, response: initialResponse = {} } = response.data;
      console.info(initialResponse, 'initial response dasboard');
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_DASHBOARD_TABS_SUCCESS,
          payload: initialResponse
        });
      }
      else {
        dispatch({
          type: GET_DASHBOARD_TABS_ERROR,
          payload: initialResponse.message
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_DASHBOARD_TABS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Roster Report Status
export const getRosterReportStatus = (organizationId, enqueueSnackbar, handleRosterPopup, handleRefresh) => {
  return async dispatch => {
    dispatch({
      type: GET_ROSTER_REPORT_STATUS_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ROSTER_REPORT_STATUS_URL,
      headers: authHeader(),
      params: { OrganizationID: parseInt(organizationId) }
    };
    API(options)
      .then(response => {
        const { status, response: initialResponse = {}, message = '' } = response.data;
        const { RosterState = 0 } = initialResponse
        if (parseInt(status) === 1) {
          // console.info(initialResponse, 'initialResponse')

          dispatch({
            type: GET_ROSTER_REPORT_STATUS_SUCCESS,
            payload: initialResponse
          });
          if (handleRosterPopup) {
            handleRosterPopup(RosterState);
          }
          if (handleRefresh && RosterState == 2) {
            handleRefresh()
          }
        } else {
          CustomAlertMessage(message, 'error', enqueueSnackbar);
          dispatch({
            type: GET_ROSTER_REPORT_STATUS_ERROR,
            payload: { message: message }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ROSTER_REPORT_STATUS_ERROR,
            payload: data.message
          });
        }
      });
  };
}

export function GetTicketPurchaseOrderHistory(EntityTypeID, SourceOwnerID) {
  return async dispatch => {
    dispatch({
      type: GET_TICKET_PURCHASE_ORDER_HISTORY_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_TICKET_PURCHASE_ORDER_HISTORY_URL,
      headers: authHeader(),
      params: { EntityTypeID, SourceOwnerID }
    };
    API(options).then(response => {
      const { status } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_TICKET_PURCHASE_ORDER_HISTORY_SUCCESS,
          payload: response.data.response
        });

      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_TICKET_PURCHASE_ORDER_HISTORY_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get Member
export function getMemberPendingRosterEntries() {
  return async dispatch => {
    dispatch({
      type: GET_MEMBER_PENDING_ROSTER_ENTRIES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_MEMBER_PENDING_ROSTER_ENTRIES_URL,
      headers: authHeader(),

    };
    API(options).then(response => {
      const { status, response: apiData } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_MEMBER_PENDING_ROSTER_ENTRIES_SUCCESS,
          payload: apiData
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_MEMBER_PENDING_ROSTER_ENTRIES_ERROR,
            payload: data.message
          });
        }
      });
  };
}
