export const FIVE_DIGIT_NUMBER_REGEX = /^\d{5}$/;
export const US_PHONE_NUMBER_REGEX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const MINIMUM_EIGHT_MIXED_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&_]{8,}$/;
export const MAXIMUM_FIVE_DIGITS_REGEX = /^[0-9]{3,5}$/;
export const NINE_DIGIT_REGEX = /^\d{9}$/;
export const SIX_DIGIT_OTP_CODE_REGEX = /^\d{6}$/;
export const MINIMUM_SIX_CHARACTERS = /^.{6,}$/;
export const SIX_CHARACTERS_REGEX = /^[a-zA-Z0-9]{6,}$/;
export const NUMBER_REGEX = /^\d+$/;
export const FLOAT_VALUE_REGEX = /^[+-]?([0-9]*[.])?[0-9]+$/;
export const NINE_DIGIT_NUMBER_REGEX = /^\d{9}$/;
export const NAME_REGEX =/^[A-Z]/;
export const EMAIL_REGEX = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/
export const STRING_REGEX = /^[aA-zZ\s]+$/
export const ZIPCODE_REGEX =/^[0-9]{3,5}$/

